import { push, replace, routerActions } from "react-router-redux";
import { call, fork, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import { AVAILABLE_REGIONS, REGION_REST_OF_LATAM, REGION_USA } from "constants.js";
import * as creditCardsMiddleware from "middleware/creditCards";
import creditLines from "middleware/creditLines";
import * as session from "middleware/session";
import * as settings from "middleware/settings";
import * as softtokenApi from "middleware/softToken/softToken";
import * as secondFactor from "middleware/secondFactor";
import { actions as assistantActions, selectors as assistantSelectors } from "reducers/assistant";
import { actions as creditCardActions } from "reducers/creditCard";
import { actions as actionsCreditLines } from "reducers/creditLines";
import { actions as desktopActions } from "reducers/desktop";
import { types as enrollmentTypes } from "reducers/enrollment";
import { actions as i18nActions } from "reducers/i18n";
import { actions as loginActions, selectors, types } from "reducers/login";
import { actions as notificationActions } from "reducers/notification";
import { actions as sessionActions, selectors as sessionSelectors, types as sessionTypes } from "reducers/session";
import { actions as statusActions } from "reducers/status";
import { actions as tourActions } from "reducers/tour";
import { types as transactionTypes } from "reducers/transactions";
import { actions as secondFactorActions, types as secondFactorTypes, selectors as secondFactorSelectors } from "reducers/secondFactor";
import { softTokenTypes, actions as softTokenActions } from "reducers/softToken";
import formTypes from "reducers/types/form";
import globalTypes from "reducers/types/global";
import { BIOMETRIC_CALLBACK_SAVE_TOKEN } from "util/biometric.util";
import {
    RESPONSE_SUCCESS_CODE,
    decryptCredential,
    deleteBiometricCredentials,
    getCurrentCredentials,
    requestFingerPrinterDecrypt,
} from "util/biometricMigrate/biometricMigrate.util";
import * as configUtils from "util/config";
import * as deviceUtils from "util/device";
import * as fingerprintUtils from "util/fingerprint";
import { adjustIdFieldErrors } from "util/form";
import * as i18n from "util/i18n";
import { generateUserNameSession } from "util/revelock";
import { setPositionRevelock, setSessionIdRevelock, setUserIdRevelock } from "util/revelockMobile/revelockMobile.util";
import * as secureStorageUtils from "util/secureStorage";
import {
    USER_TOKEN_STATUS_AUTHENTICATE,
    USER_TOKEN_STATUS_INACTIVE,
    USER_TOKEN_STATUS_MIGRATE_DIFERENT_UUID,
    USER_TOKEN_STATUS_MIGRATE_ENTRUST,
    USER_TOKEN_STATUS_MIGRATE_LOCAL,
    USER_TOKEN_STATUS_ACTIVE,
} from "util/userToken.util";
import { isMobileNativeFunc } from "util/device";
import { ENT000, hasRegisteredIdentity, generateOTP } from "util/softToken.util";
import * as secondFactorUtils from "util/secondFactorUtils";
import { store } from "../store";

const sagas = [
    takeEvery(types.RESET, reset),

    takeLatest(types.LOGIN_STEP_1_USERNAME_REQUEST, handleLoginStep1UsernameRequest),
    takeLatest(types.LOGIN_STEP_2_PASSWORD_REQUEST, handleLoginStep2PasswordRequest),
    takeLatest(types.LOGIN_STEP_3_VERIFICATION_REQUEST, handleLoginStep3VerificationRequest),
    takeLatest(types.LOGIN_OAUTH_REQUEST, handleOauthRequest),
    takeLatest(types.LOGIN_STEP_4_REQUEST, handleLoginStep4Request),
    takeLatest(types.FINALIZE_LOGIN, handleFinalizeLogin),
    takeLatest(types.BIOMETRIC_LOGIN_REQUEST, handleBiometricLogin),
    takeLatest(types.GO_BACK_LOGIN_SHOW_MESSAGE, goBackToLoginAndShowMessage),
    takeLatest(types.GET_CLIENT_COUNTRY_REQ, getClientCountry),
    takeLatest(types.LOGIN_FINGERPRINTER_STEP_4_REQUEST, fingerPrinterFourthStepRequest),
    takeLatest(types.VALIDATE_BIOMETRIC_MIGRATION, validateBiometricMigration),
    takeLatest(types.REMOVE_NOTIFY_ENVIRONMENT_REQUEST, removeNotifyEnvironments),
    takeLatest(types.FINGERPRINT_CHECK_SESSION, checkFingerprintValidSession),
];

export default sagas;

function* getClientCountry() {
    const activeRegion = yield select(selectors.getRegion);
    if (!activeRegion) {
        try {
            const { type, data } = yield call(session.getClientCountry);
            if (type !== "W") {
                const { country } = data.data;
                if (country) {
                    yield put({ type: types.GET_CLIENT_COUNTRY_RES, clientCountry: country.iso_code });

                    // Verify if the client country is in our supported countries
                    if (AVAILABLE_REGIONS.indexOf(country.iso_code) !== -1) {
                        yield put({ type: types.SET_REGION, region: country.iso_code });
                    } else {
                        yield put({ type: types.SET_REGION, region: REGION_REST_OF_LATAM });
                    }
                }
            } else {
                yield put({ type: types.SET_REGION, region: REGION_REST_OF_LATAM });
            }
        } catch (e) {
            yield put({ type: types.SET_REGION, region: REGION_REST_OF_LATAM });
        }
    }
}

function* goBackToLoginAndShowMessage({ message }) {
    yield put(notificationActions.showNotification(message, "error", ["externalLayout"]));
    yield put({ type: globalTypes.BACK_TO_STEP_0 });
    yield put(replace("/"));
}

function* reset() {
    yield put(routerActions.replace({ pathname: "/loginStep1" }));
}

function* showErrorUserToken() {
    yield put(
        notificationActions.showNotification(
            i18n.get("token.entrust.validateStatus.error.message"),
            "error",
            ["menu"],
            false,
        ),
    );
    yield put(replace("/desktop"));
    yield put(statusActions.resetComeFromLogin());
}

function* redirectBiometricValidation(pathRedirect) {
    const callbackDataSelfie = {
        type: BIOMETRIC_CALLBACK_SAVE_TOKEN,
        data: {
            deviceUuid: window?.app?.getDeviceUUID() || "",
            deviceModel: window?.app?.model || "",
            deviceBrand: window?.app?.manufacturer || "",
        },
        redirectSuccess: "/auth/tokenActivationSuccess",
        redirectError: "/auth/tokenEntrustActivationFailed",
        scopeError: [],
        scopeSuccess: [],
        redirectDocument: "/auth/tokenActivationPending",
        redirectAbort: "/desktop",
    };

    yield put({ type: enrollmentTypes.SET_CALLBACK_DATA_SELFIE, callbackDataSelfie });
    yield put(
        routerActions.push({
            pathname: pathRedirect || "/auth/tokenActivationStep1",
        }),
    );

    yield put(statusActions.deleteLastHref());
}

function* validateUserToken() {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    if (!deviceUuid) {
        yield call(showErrorUserToken);
        yield;
        return;
    }
    const validateStatusTokenResponse = yield call(softtokenApi.validateStatusToken, deviceUuid);
    if (!validateStatusTokenResponse) {
        yield call(showErrorUserToken);
        yield;
        return;
    }
    const { data, type } = validateStatusTokenResponse;
    if (!type || type === "W" || !data) {
        yield call(showErrorUserToken);
        yield;
        return;
    }
    const { tokenStatus } = data?.data;
    if (!tokenStatus) {
        yield call(showErrorUserToken);
        yield;
        return;
    }

    if (tokenStatus === USER_TOKEN_STATUS_INACTIVE) {
        yield call(redirectBiometricValidation);
    } else if (tokenStatus === USER_TOKEN_STATUS_MIGRATE_DIFERENT_UUID) {
        yield put(
            notificationActions.showNotification(
                i18n.get("token.entrust.migrateEntrust.validate.error.message"),
                "error",
                ["tokenActivation"],
                false,
            ),
        );
        yield call(redirectBiometricValidation, "/auth/tokenActivationStep1RedirectSession");
    } else if (tokenStatus === USER_TOKEN_STATUS_AUTHENTICATE) {
        yield put(replace("/authenticateSofttoken"));
        yield put(statusActions.deleteLastHref());
    } else if (tokenStatus === USER_TOKEN_STATUS_MIGRATE_LOCAL) {
        yield call(redirectBiometricValidation);
        yield put(statusActions.deleteLastHref());
    } else if (tokenStatus === USER_TOKEN_STATUS_MIGRATE_ENTRUST) {
        yield put(replace("/migrateSoftTokenEntrust"));
        yield put(statusActions.deleteLastHref());
    } else {
        yield call(showErrorUserToken);
    }
}

function* setUserRevelock(userId, sessionId) {
    if (!deviceUtils.isMobileNativeFunc()) {
        yield;
        return;
    }

    yield call(setSessionIdRevelock, sessionId || "");
    yield call(setUserIdRevelock, generateUserNameSession(userId));
}

function* registerDefaultFingerPrinter(access_token, username) {
    const responseSaveFingerPrinter = yield call(
        settings.saveFingerprint,
        deviceUtils.getDeviceId(),
        deviceUtils.getDeviceModel(),
    );

    if (!responseSaveFingerPrinter?.status || responseSaveFingerPrinter.status !== 200) {
        yield;
        return;
    }

    const resultFingerprintAuthToken = yield call(
        secureStorageUtils.set,
        "fingerprintAuthToken",
        sessionSelectors.getAccessToken(store.getState()),
    );

    if (!resultFingerprintAuthToken) {
        yield;
        return;
    }

    const resultFingerprintUsername = yield call(secureStorageUtils.set, "fingerprintUsername", username);

    if (!resultFingerprintUsername) {
        yield;
        return;
    }

    try {
        yield call(deleteBiometricCredentials);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
}

function* handleFinalizeLogin({ response }) {
    const activeRegion = yield select(selectors.getRegion);
    const {
        isAdministrator,
        generalConditions,
        _accessToken: accessToken,
        newEnvironments,
        daysBeforeExpirePassword,
        notifyPasswordExpiration,
    } = response.data.data;
    const exchangeToken = yield select(selectors.getExchangeToken);
    const username = yield select(selectors.getUsername);
    const isAssistantLogin = yield select(assistantSelectors.isAssistantLogin);

    if (generalConditions) {
        const {
            generalConditionsShowExpiration,
            generalConditionsText,
            generalConditionsExpirationDate,
        } = response.data.data;
        const termsAndConditions = {
            // just joining the long named variables into a single object
            showExpiration: generalConditionsShowExpiration,
            generalConditions,
            body: generalConditionsText,
            expirationDate: generalConditionsExpirationDate,
        };

        yield put({ type: types.LOGIN_STEP_3_VERIFICATION_SUCCESS, termsAndConditions });
        if (activeRegion === REGION_USA) {
            const firstName = yield select(selectors.getUserFirstName);
            const email = yield select(selectors.getUsername);
            yield put({
                type: enrollmentTypes.SET_INVITATION,
                invitation: {
                    email,
                    firstName,
                    lastName: "",
                },
            });
            yield put(replace("/enrollment/Step3Part4"));
        } else {
            yield put(replace("/loginStep4"));
        }
    } else if (isAssistantLogin) {
        yield put(assistantActions.setAccessToken(accessToken));
        yield put(replace("/loginStep5"));
    } else {
        const loginData = yield call(processLoginSuccess, response);
        if (loginData.user.isNeedingUpdate) {
            yield put(desktopActions.updateModalShow(loginData.user.daysSinceLastUpdt));
        }
        const { environments, lang } = response.data.data;
        const { lastHref } = yield select((state) => state.status);

        configUtils.setRecaptchaLang(lang);
        yield put(i18nActions.setLang(lang));

        const responseOAuth = yield call(session.oauth, username, exchangeToken);
        let accessTokenResponse = "";
        if (responseOAuth.type === "W") {
            const errorMsg = i18n.get(
                `returnCode.${responseOAuth.data.error_description}`,
                responseOAuth.data.error_description,
            );
            yield put({ type: types.LOGIN_OAUTH_FAILURE });
            // exchangeToken expired, restart flow
            yield put(notificationActions.showNotification(errorMsg, "error", ["externalLayout"]));
            yield put({ type: globalTypes.BACK_TO_STEP_0 });
            yield put(replace("/"));
        } else {
            const { access_token, refresh_token } = responseOAuth.data;
            accessTokenResponse = accessToken;
            session.setAuthToken(access_token);
            yield put(sessionActions.setTokens(access_token, refresh_token));
            /**
             * Get session data
             */
            const { data: sessionData } = yield call(session.check);
            yield put({
                type: types.LOGIN_SUCCESS,
                environment: loginData.environment,
                user: { ...loginData.user, sessionId: sessionData?.data?.sessionId || "" },
                environments,
                isAdministrator,
                daysBeforeExpirePassword,
                notifyPasswordExpiration,
            });

            /**
             * Validate biometric migrate
             */

            const isFromBiometricMigration = yield select(selectors.isFromBiometricMigration);
            if (
                isFromBiometricMigration !== undefined &&
                (isFromBiometricMigration === true || isFromBiometricMigration === "true")
            ) {
                yield call(registerDefaultFingerPrinter, accessTokenResponse, loginData?.user?.username);
            }

            /**
             * Set user id revelock
             */
            yield call(setUserRevelock, loginData?.user?.username, sessionData?.data?.sessionId);

            /**
             * Update mask amount
             */
            const { productsMaskAmount } = loginData.user;
            if (productsMaskAmount) {
                yield put(sessionActions.maskAmountUpdate(productsMaskAmount));
            }

            // if (!isFirstLogin) {
            //     yield put(productActions.syncEnviromentProduct(true));
            // }

            yield fork(readCreditCardList);
            yield fork(readCreditLineList);
            const { pepCompleted, irsCompleted } = loginData.user;
            if ((!pepCompleted || !irsCompleted) && activeRegion === REGION_USA) {
                yield put(replace("/pendingActions"));
            } else if (lastHref) {
                if (lastHref === "/tokenActivationStep1") {
                    yield call(validateUserToken);
                } else {
                    yield put(
                        replace({
                            pathname: editLastHrefByConditions(lastHref, loginData.environment),
                            state: { isFromPublicPath: true },
                        }),
                    );
                    yield put(statusActions.deleteLastHref());
                }
            } else if (newEnvironments && newEnvironments.length > 0) {
                yield put(replace("/newEnvironments"));
            } else {
                yield put(statusActions.resetComeFromLogin());
                yield put(replace("/desktop"));
            }
        }
    }
}

function editLastHrefByConditions(lastHref, environment) {
    const { permissions } = environment;

    // card payment
    if (
        lastHref === "/formCustom/payCreditCardLocal" &&
        (permissions.payCreditCard || permissions.payCreditCardThird)
    ) {
        if (permissions.payCreditCard) {
            return lastHref;
        }
        if (permissions.payCreditCardThird) {
            return "/formCustom/payCreditCardThird";
        }
    }

    // card recharge
    if (
        lastHref === "/formCustom/rechargeCreditCardLocal" &&
        (permissions.rechargeCreditCardLocal || permissions.rechargeCreditCardThird)
    ) {
        if (permissions.rechargeCreditCardLocal) {
            return lastHref;
        }
        if (permissions.rechargeCreditCardThird) {
            return "/formCustom/rechargeCreditCardThird";
        }
    }

    return lastHref;
}

export function* readCreditCardList(idCreditCard) {
    yield put(creditCardActions.listFetching());
    yield put(creditCardActions.listFetchingCards());
    const responseCreditCard = yield call(creditCardsMiddleware.listRequest);
    if (responseCreditCard.type === "W") {
        yield put(creditCardActions.listFailure());
    } else {
        const { creditCards } = responseCreditCard.data.data;
        yield put(creditCardActions.listSuccess(creditCards));
        if (idCreditCard) {
            yield put(creditCardActions.detailEveryRequest(idCreditCard));
        } else {
            for (let i = 0; i < creditCards.length; i++) {
                if (!creditCards[i].isAditional) {
                    yield put(creditCardActions.detailEveryRequest(creditCards[i].idProduct));
                }
            }
        }
    }
}

export function* readCreditLineList() {
    const response = yield call(creditLines);
    if (response.type === "W") {
        yield put(actionsCreditLines.listFailure());
    } else {
        const { creditLines: responseCreditLines, total } = response.data.data;
        yield put(actionsCreditLines.listSuccess(responseCreditLines, total));
        if (responseCreditLines.length === 0) {
            yield put(desktopActions.loadLayoutRemoveWidget("creditLines"));
        }
    }
}

function* handleLoginStep1UsernameRequest({ email, shouldRememberEmail, formikBag, onFinish = () => {} }) {
    try {
        const response = yield call(session.loginStep0, email);

        if (response.data.code === "API601W") {
            let resetForm = false;
            const urlSAT = configUtils.get("login.previous.SAT.url");
            const enabledRedirect = configUtils.getBoolean("previous.SAT.enabled.redirect");
            if (deviceUtils.isMobileNativeFunc()) {
                yield put(
                    notificationActions.showNotification(i18n.get("login.app.user.notFound.message"), "error", [
                        "login",
                    ]),
                );
                resetForm = true;
            } else if (enabledRedirect && urlSAT) {
                yield call(() => window.open(urlSAT + email, "_blank"));
                resetForm = true;
            }
            if (resetForm && formikBag) {
                formikBag.setSubmitting(false);
                formikBag.resetForm();
                onFinish();
                return;
            }
        }
        if (response.data.code === "COR020W") {
            if (formikBag) {
                formikBag.setSubmitting(false);
            }

            yield put({ type: types.LOGIN_FAILURE });
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        } else if (response.data.code === "COR019E") {
            if (formikBag) {
                formikBag.setSubmitting(false);
            }

            yield put({ type: types.LOGIN_FAILURE_USER_BLOQUED });
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));

            yield put(replace("/userBlocked"));
        } else if (response.data.code === "API605W") {
            if (formikBag) {
                formikBag.setSubmitting(false);
            }

            yield put({ type: types.LOGIN_FAILURE_USER_BLOQUED });

            yield put(replace("/userBlockedBank"));
        } else if (response.data.code === "API720W") {
            if (formikBag) {
                formikBag.setSubmitting(false);
            }
            yield put(replace("/errorActiveSession"));
        } else if (
            response.data.code === "API519W" ||
            response.data.code === "API517W" ||
            response.data.code === "API016W" ||
            response.data.code === "COR029E" ||
            response.data.code === "COR047E" ||
            response.data.code === "API582W"
        ) {
            // Wrong credentials || capcha required || capcha invalid
            if (formikBag) {
                formikBag.setSubmitting(false);
            }

            yield put(notificationActions.showNotification(response.data.message, "error", ["externalLayout"]));
            yield put({ type: types.LOGIN_FAILURE });

            yield put({
                type: formTypes.SEND_FORM_DATA_FAILURE,
                idForm: "loginStep1Username",
                code: response.data.code,
                errors: response.data.data,
            });
        } else if (response.data.code === "API709W") {
            yield put({ type: types.SHOW_PENDING_INVITATION_MODAL, showPendingInvitationModal: true });
            if (formikBag) {
                formikBag.setSubmitting(false);
            }
        } else {
            const { _securitySeal, _securitySealAlt } = response.data.data;

            yield put({
                type: types.LOGIN_STEP_1_USERNAME_SUCCESS,
                username: email,
                securitySeal: _securitySeal,
                securitySealAlt: _securitySealAlt,
                shouldRememberEmail,
            });

            if (formikBag) {
                formikBag.setSubmitting(false);
            }
            yield put(replace("/loginStep2"));
        }
    } catch (e) {
        if (e?.data?.code === "COR019E") {
            if (formikBag) {
                formikBag.setSubmitting(false);
            }

            yield put({ type: types.LOGIN_FAILURE_USER_BLOQUED });

            yield put(replace("/userBlocked"));
        } else if (e?.data?.code === "API605W") {
            if (formikBag) {
                formikBag.setSubmitting(false);
            }

            yield put({ type: types.LOGIN_FAILURE_USER_BLOQUED });

            yield put(replace("/userBlockedBank"));
        } else if (e?.data?.code === "COR005E") {
            yield put(replace("/"));
            yield put(notificationActions.showNotification(e.data.message, "warning", ["externalLayout"]));
        } else if (e?.data?.code === "COR121E") {
            yield put(notificationActions.showNotification(e.data.message, "warning", ["externalLayout"]));
        } else if (e?.data?.code === "COR122E") {
            yield put(notificationActions.showNotification(e.data.message, "warning", ["externalLayout"]));
        } else if (e?.data?.code === "COR047E") {
            yield put(notificationActions.showNotification(e.data.message, "warning", ["externalLayout"]));
        } else if (e?.data?.code === "COR123E") {
            if (formikBag) {
                formikBag.setSubmitting(false);
            }
            yield put({ type: types.LOGIN_FAILURE_USER_BLOQUED });
            yield put(replace("/userBlockedAdmin"));
        } else {
            yield put(replace("/loginStep1"));
        }
    } finally{
        yield put({ type: types.LOGIN_FAILURE });
    }
    onFinish();
}
function* handleLoginStep2PasswordRequest({ password, formikBag, isActiveCorporate }) {
    // const exchangeToken = yield select(selectors.getExchangeToken);
    const username = yield select(selectors.getUsername);
    const credentialType = "password";
    const recaptchaResponse = "";
    const otp = "";

    const response = yield call(session.loginStep1, username, password, recaptchaResponse, credentialType, otp);

    // password caducado
    if (response.data.code === "API704W") {
        if (formikBag) {
            formikBag.setSubmitting(false);
        }
        yield put({ type: types.EXPIRED_PASSWORD, username });
        yield put(replace("/expiredPassword"));
    } else if (response.data.code === "COR020W") {
        if (formikBag) {
            formikBag.setSubmitting(false);
        }

        yield put({ type: types.LOGIN_FAILURE });
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
    } else if (response.data.code === "API517W") {
        yield put({ type: types.LOGIN_FAILURE_USER_BLOQUED });
        yield put(replace("/userBlocked"));
    } else if (
        response.data.code === "API519W" ||
        response.data.code === "API516W" ||
        response.data.code === "API016W" ||
        response.data.code === "API021W" ||
        response.data.code === "COR050W" ||
        response.data.code === "COR029E" ||
        response.data.code === "COR047E" ||
        response.data.code === "API582W"
    ) {
        // Wrong credentials || capcha required || capcha invalid
        if (formikBag) {
            formikBag.setSubmitting(false);
        }

        yield put(notificationActions.showNotification(response.data.message, "error", ["externalLayout"]));
        yield put({ type: types.LOGIN_FAILURE });

        yield put({
            type: formTypes.SEND_FORM_DATA_FAILURE,
            idForm: "loginStep2",
            code: response.data.code,
            errors: response.data.data,
        });
    } else {
        const {
            _exchangeToken,
            lang,
            environments,
            _userFirstName,
            _userFullName,
            // selectEnvironment,
            defEnvironment,
            defEnvironmentEnabled,
            // enabledAssistant,
            // isOnboardingProspect,
            email,
            mobileNumber,
        } = response.data.data;

        if (!defEnvironmentEnabled) {
            yield put(
                notificationActions.showNotification(i18n.get("settings.defaultEnvironment.blockedMessage"), "error", [
                    "loginStep3",
                ]),
            );
        }

        // Remember user
        const shouldRememberEmail = yield select(selectors.getShouldRememberEmail);
        if (shouldRememberEmail) {
            yield put(
                loginActions.setRememberedUser({
                    username,
                    userFirstName: _userFirstName,
                    userFullName: _userFullName,
                    isUserActiveCorporate: isActiveCorporate,
                }),
            );
        }

        /*
        yield put({
            type: types.LOGIN_STEP_2_PASSWORD_SUCCESS,
            credentialType: "otp", // "digitalKey" "otp"
            otpMethod: "email", // "phone" "email"   "appEntrust" "appBancaEmpresas" "securityDevice"
            exchangeToken: _exchangeToken,
            lang,
            _userFirstName,
            _userFullName,
            environments,
            defEnvironment,
        });*/

        // yield put({ type: sessionTypes.SET_MEDIA_CONTACT, email, mobileNumber });

        // yield put(secondFactorActions.secondFactorStatusTokenRequest({ exchangeToken: _exchangeToken }));

        console.log("login.js Determinando segundo factor de autenticacion desde step2: ");
        try{
            let hasTokenSeed = yield call(getLoginSecondFactorSeed);
            const credentials = yield call(getLoginSecondFactor,"session.login.legacy.step3", _exchangeToken, hasTokenSeed);
            if(!credentials){
                if (formikBag) {
                    formikBag.setSubmitting(false);
                }
        
                yield put(notificationActions.showNotification("No tiene factor de autenticaci\u00F3n robusto para continuar", "error", ["externalLayout"]));
                yield put({ type: types.LOGIN_FAILURE });
        
                yield put({
                    type: formTypes.SEND_FORM_DATA_FAILURE,
                    idForm: "loginStep2",
                    code: response.data.code,
                    errors: response.data.data,
                });
                return;
            }
            console.log("login.js Credenciales obtenidas: ",credentials);
            const credential = credentials[0];
            console.log("login.js Credenciales obtenidas: credential: ",credential);
            console.log("login.js Credenciales obtenidas: credential.credentialType: ",credential.credentialType);
            switch (credential.credentialType) {
                case secondFactorUtils.SECOND_FACTOR_CONST.SOFT_HARD_TOKEN:
                    let tokenStatus = yield call(getLoginSecondFactorStatus, _exchangeToken);
                    const isDigitalTokenMethod = credential.credentialMethod === secondFactorUtils.SECOND_FACTOR_METHOD_CONST.DIGITAL_TOKEN;
                    
                    if(isMobileNativeFunc() && (hasTokenSeed && tokenStatus === USER_TOKEN_STATUS_ACTIVE && isDigitalTokenMethod)){
                        console.log("login.js Credenciales SOFT_HARD_TOKEN LOGIN_STEP_2_TRANSITORY_SUCCESS: "); 
                        yield put({
                            type: types.LOGIN_STEP_2_TRANSITORY_SUCCESS,
                            credentialType: credential.credentialType, // "digitalKey" "otp"
                            otpMethod: credential.credentialMethod, // "phone" "email"   "appEntrust" "appBancaEmpresas" "securityDevice"
                            exchangeToken: _exchangeToken,
                            lang,
                            _userFirstName,
                            _userFullName,
                            environments,
                            defEnvironment,
                        });
                        console.log("login.js Credenciales SOFT_HARD_TOKEN: ");  
                        const tokenValue = yield call(getOtpCode);
                        console.log("login.js Credenciales SOFT_HARD_TOKEN loginWithDigitalTokenRequest tokenValue: ", tokenValue);
                        // yield put(replace("/loginStep3")); //otra transitoria
                        // yield put(replace("/newEnvironments"));
                        // yield put(loginActions.loginStep3Verification(formikBag, defEnvironment, "false", tokenValue));
                        yield call(handleLoginStep3VerificationRequest, {formikBag, idEnvironment: defEnvironment, rememberEnvironment: "false", isUserActiveCorporate: true, secondFactor: tokenValue});
                        // yield call(finalizeLoginStep3, "digitalKey","email",_exchangeToken,lang,_userFirstName,_userFullName,environments,defEnvironment,formikBag, defEnvironment, "false", true, tokenValue);
                        console.log("login.js Credenciales SOFT_HARD_TOKEN Finalizando step2 y step3 : ", tokenValue);
                        /* yield put({
                            type: types.LOGIN_STEP_2_PASSWORD_SUCCESS,
                            credentialType: credential.credentialType, // "digitalKey" "otp"
                            otpMethod: credential.credentialMethod, // "phone" "email"   "appEntrust" "appBancaEmpresas" "securityDevice"
                            exchangeToken: _exchangeToken,
                            lang,
                            _userFirstName,
                            _userFullName,
                            environments,
                            defEnvironment,
                        }); */
                    }else{
                        yield put({
                            type: types.LOGIN_STEP_2_PASSWORD_SUCCESS,
                            credentialType: credential.credentialType, // "digitalKey" "otp"
                            otpMethod: credential.credentialMethod, // "phone" "email"   "appEntrust" "appBancaEmpresas" "securityDevice"
                            exchangeToken: _exchangeToken,
                            lang,
                            _userFirstName,
                            _userFullName,
                            environments,
                            defEnvironment,
                        });
                        yield put({ type: sessionTypes.SET_MEDIA_CONTACT, email, mobileNumber });
                        yield put(replace("/loginStep3"));
                    }
                    break; 
                case secondFactorUtils.SECOND_FACTOR_CONST.SECURITY_QUESTIONS:
                    console.log("login.js Credenciales SECURITY_QUESTIONS: No se aplica mejora"); 
                    yield put({
                        type: types.LOGIN_STEP_2_PASSWORD_SUCCESS,
                        credentialType: "otp", // "digitalKey" "otp"
                        otpMethod: "email", // "phone" "email"   "appEntrust" "appBancaEmpresas" "securityDevice"
                        exchangeToken: _exchangeToken,
                        lang,
                        _userFirstName,
                        _userFullName,
                        environments,
                        defEnvironment,
                    }); 
                    yield put({ type: sessionTypes.SET_MEDIA_CONTACT, email, mobileNumber });
                    yield put(replace("/loginStep3"));
                    break;  
                case secondFactorUtils.SECOND_FACTOR_CONST.OTP:
                    console.log("login.js Credenciales OTP: Obteniendo codigo de verificacion desde step2: ");  
                    yield put({
                        type: types.LOGIN_STEP_2_PASSWORD_SUCCESS,
                        credentialType: "otp", // "digitalKey" "otp"
                        otpMethod: "email", // "phone" "email"   "appEntrust" "appBancaEmpresas" "securityDevice"
                        exchangeToken: _exchangeToken,
                        lang,
                        _userFirstName,
                        _userFullName,
                        environments,
                        defEnvironment,
                    });  
                    yield put({ type: sessionTypes.SET_MEDIA_CONTACT, email, mobileNumber });
                    yield put(secondFactorActions.secondFactorVerificationCodeRequest({ idActivity: "session.login.legacy.step3", exchangeToken: _exchangeToken, scopeToShow: "externalLayout" }));
                    yield put(replace("/loginStep3"));
                    break;
                default:
                    console.log("login.js Credenciales credential.credentialType NO DEFINIDO: Interrumpir flujo");
                    if (formikBag) {
                        formikBag.setSubmitting(false);
                    }
            
                    yield put(notificationActions.showNotification("No tiene factor de autenticaci\u00F3n robusto para continuar", "error", ["externalLayout"]));
                    yield put({ type: types.LOGIN_FAILURE });
            
                    yield put({
                        type: formTypes.SEND_FORM_DATA_FAILURE,
                        idForm: "loginStep2",
                        code: response.data.code,
                        errors: response.data.data,
                    });
                    return;
                    break;
                }
        } catch (error) {
            console.log("Ha ocurrido un error al determinar el segundo factor en el step2/3: ", error);
        }
        // yield put(replace("/loginStep3"));

        // if (selectEnvironment && Object.keys(environments).length > 1) {
        //     if (formikBag) {
        //         formikBag.setSubmitting(false);
        //     }

        //     yield put(replace("/loginStep3"));
        // } else {
        //     yield put({
        //         type: types.LOGIN_STEP_3_REQUEST,
        //         formikBag,
        //     });
        // }
    }
}

function* getLoginSecondFactorSeed() {
    let hasTokenSeed = false;
    if (isMobileNativeFunc()) {
        hasTokenSeed = yield select(secondFactorSelectors.getUserHasToken);
        console.log("login.js getLoginSecondFactorSeed hasTokenSeed: ", hasTokenSeed);
        if (!hasTokenSeed) {
            const hasTokenResponse = yield call(hasRegisteredIdentity);
            if (!hasTokenResponse) {
                hasTokenSeed = false;
            }

            const { code: codeIdentity, data: dataIdentity } = JSON.parse(hasTokenResponse);
            if (!codeIdentity || !dataIdentity || codeIdentity !== ENT000 || dataIdentity !== "true") {
                hasTokenSeed = false;
            }
            hasTokenSeed=dataIdentity;
        }
    }

    console.log("login.js getLoginSecondFactorSeed hasTokenSeed: ", hasTokenSeed);
    if (hasTokenSeed) {
        yield put(secondFactorActions.secondFactorStatusTokenSuccess(hasTokenSeed));
    }
    return hasTokenSeed;
}

function* getLoginSecondFactorStatus(exchangeToken) {
    let secondFactorStatus;
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    const response = yield call(softtokenApi.validateStatusToken, deviceUuid, exchangeToken);
    if (!response?.type || !response?.data?.data) {
        yield put({
            type: softTokenTypes.VALIDATE_TOKEN_STATUS_FAILURE,
        });
        yield;
        return;
    }
    const { type, data } = response;
    if (type === "W") {
        yield put({
            type: softTokenTypes.VALIDATE_TOKEN_STATUS_FAILURE,
        });
        yield;
        return;
    }
    const { tokenStatus } = data?.data;
    secondFactorStatus = tokenStatus;
    if (!tokenStatus) {
        yield put({
            type: softTokenTypes.VALIDATE_TOKEN_STATUS_FAILURE,
        });
        yield;
        return;
    }
    yield put(softTokenActions.getStatusTokenSuccess(tokenStatus));
    return secondFactorStatus;
}

function* getLoginSecondFactor(idActivity, exchangeToken, hasTokenSeed) {
    const deviceUUID = window?.app?.getDeviceUUID() || "";
    const deviceModel = window?.device?.model || "";
    const deviceBrand = window?.device?.manufacturer || "";
    let secondFactorCredentials;

    try{
        const response = yield call(
            secondFactor.secondFactorPreview,
            deviceUUID,
            deviceModel,
            deviceBrand,
            hasTokenSeed,
            idActivity,
            exchangeToken,
        );

        console.log("login.js getLoginSecondFactor finaliza llamada secondFactor.secondFactorPreview: ", response);
        if (response?.data?.code === "API710W") {
            yield put(routerActions.replace("/deviceRequired"));
            return;
        }
        if (!response?.type || !response?.data?.data) {
            yield put({
                type: secondFactorTypes.SECOND_FACTOR_PREVIEW_FAILURE,
            });
            yield;
            return;
        }
        const { type, data } = response;
        if (type === "W") {
            yield put({
                type: secondFactorTypes.SECOND_FACTOR_PREVIEW_FAILURE,
            });
            yield;
            return;
        }
        const { credentials, _email, _mobileNumber, attempts, configuredToken, idActivityToRead} = data?.data;

        secondFactorCredentials = credentials;

        if (!credentials) {
            yield put({
                type: secondFactorTypes.SECOND_FACTOR_PREVIEW_FAILURE,
            });
            yield;
            return;
        }
        if (_email) {
            yield put({
                type: secondFactorTypes.SECOND_FACTOR_SET_CONTACT_OTP,
                email: _email,
                mobileNumber: _mobileNumber,
            });
        }
        console.log("login.js getLoginSecondFactor finaliza put secondFactorPreviewSuccess: ", credentials, attempts);
        yield put(secondFactorActions.secondFactorPreviewSuccess(credentials, attempts, configuredToken, idActivityToRead));
    }catch(error){
        console.log("Ha ocurrido un error al obtener segundo factor de autenticacion: ", error);
    }
    
    return secondFactorCredentials;
}

// function* validateDevice({ formikBag, idEnvironment, rememberEnvironment, isUserActiveCorporate }) {
//     const exchangeToken = yield select(selectors.getExchangeToken);
//     const isFromMessenger = yield select(assistantSelectors.isFromMessenger);
//     const isFromGoogle = yield select(assistantSelectors.isFromGoogle);
//     const isFromAmazon = yield select(assistantSelectors.isFromAmazon);
//     const isFromWhatsapp = yield select(assistantSelectors.isFromWhatsapp);
//     const clientNumber = yield select(assistantSelectors.getClientNumber);
//     const accountLinkingToken = yield select(assistantSelectors.getAccountLinkingToken);

//     const location = {
//         latitude: 0.0,
//         longitude: 0.0,
//     };

//     let extraInfo = {};
//     let idDevice = null;
//     let registrationId = null;

//     if (window.app) {
//         const { registrationId: registrationIdResult } = yield call(window.pushNotifications.isEnabled);
//         extraInfo = window.device;
//         extraInfo.isTablet = false;
//         extraInfo.isIOS = deviceUtils.getMobileOS(deviceUtils.getDisplay()) === deviceUtils.IOS_DEVICE;
//         extraInfo.isAndroid = deviceUtils.getMobileOS(deviceUtils.getDisplay()) === deviceUtils.ANDROID_DEVICE;
//         extraInfo.uuid = window.app.getDeviceUUID();
//         registrationId = registrationIdResult;
//         idDevice = window.app.getDeviceUUID();
//     } else {
//         idDevice = yield deviceUtils.getDeviceFingerprint();
//         extraInfo = yield deviceUtils.getExtraInfo();
//     }
//     yield call(session.registerUserDevice, exchangeToken, idDevice, registrationId, JSON.stringify(extraInfo));

//     const response = yield call(
//         session.loginStep3,
//         exchangeToken,
//         idEnvironment,
//         rememberEnvironment,
//         location,
//         idDevice,
//         {
//             isFromMessenger,
//             isFromGoogle,
//             isFromAmazon,
//             isFromWhatsapp,
//             accountLinkingToken,
//             clientNumber,
//         },
//     );

//     if (response.type === "W") {
//         const { code, data } = response.data;
//         if (code === "COR020W" && data.environment) {
//             // mark the environment as disabled and redirecting the user to the step 3 again
//             const environments = yield select(selectors.getEnvironments);
//             if (environments[idEnvironment]) {
//                 environments[idEnvironment].allowedToAccess = false;
//                 yield put({ type: types.MARK_ENVIRONMENTS_DISABLED, environments });
//             }

//             formikBag.setSubmitting(false);
//             yield put(replace("/loginStep3"));
//         } else {
//             // en este paso lo unico que podria suceder es que se venciera el exchange token
//             formikBag.setErrors(adjustIdFieldErrors(response.data.data));
//             yield put(notificationActions.showNotification(response.data.message, "error", ["externalLayout"]));
//             yield put({ type: globalTypes.BACK_TO_STEP_0 }); // por lo que borro lo que tengo de sesion y voy al step0

//             formikBag.setSubmitting(false);
//             yield put(replace("/"));
//         }
//     } else {
//         const { enabledAssistant, tourSkipsCounter, becameAdult, scheduledMigrationFinished, signatureLevel } =
//             response?.data?.data || {};
//         yield put({ type: types.FINALIZE_LOGIN, response, isUserActiveCorporate });
//         if (becameAdult) {
//             yield put({
//                 type: productTypes.OPEN_ADULTHOOD_MODAL,
//                 show: true,
//             });
//         }
//         yield put(tourActions.setSkipsCounter(tourSkipsCounter));
//         yield put({ type: sessionTypes.SET_ENABLED_ASSISTANT, enabledAssistant });
//         yield put({ type: sessionTypes.SET_SIGNATURE_LEVEL, signatureLevel });
//         if (scheduledMigrationFinished) {
//             yield put({ type: transactionTypes.SET_LOAD_MIGTRATION_EXECUTION_COUNT, loadMigrationExecutionCount: 3 });
//         }
//     }
// }

function* handleOauthRequest({ password, recaptchaResponse, formikBag }) {
    const exchangeToken = yield select(selectors.getExchangeToken);
    const username = yield select(selectors.getUsername);
    const isFromMessenger = yield select(assistantSelectors.isFromMessenger);
    // eslint-disable-next-line camelcase
    const { redirect_uri } = yield select(assistantSelectors.getParams);
    const isFromWhatsapp = yield select(assistantSelectors.isFromWhatsapp);

    let response;
    if (isFromMessenger || isFromWhatsapp) {
        const thirdPartyToken = yield select(assistantSelectors.getThirdPartyToken);
        response = yield call(
            session.thirdPartyOauth,
            username,
            password,
            thirdPartyToken,
            redirect_uri,
            exchangeToken,
            recaptchaResponse,
        );
        if (response.data) {
            response.data.access_token = thirdPartyToken;
        } else {
            response.data = {
                access_token: thirdPartyToken,
            };
        }
    } else {
        response = yield call(session.oauth, username, password, exchangeToken);
    }

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors({}));
        formikBag.setSubmitting(false);
        const errorMsg = i18n.get(`returnCode.${response.data.error_description}`, response.data.error_description);
        if (
            response.data.error_description === "COR020W" ||
            response.data.error_description === "API019W" ||
            response.data.error_description === "API020W" ||
            response.data.error_description === "COR050W" ||
            response.data.error_description === "COR029E" ||
            response.data.error_description === "COR047E"
        ) {
            // Wrong credentials || captcha required || captcha invalid
            yield put(notificationActions.showNotification(errorMsg, "error", ["externalLayout"]));

            if (response.data.error_description === "API020W" || response.data.error_description === "COR050W") {
                // Captcha required || captcha invalid
                yield put({ type: types.LOGIN_FAILURE_REQUIRE_CAPTCHA });
            }

            yield put({
                type: formTypes.SEND_FORM_DATA_FAILURE,
                code: response.data.error_description,
            });
            yield put({ type: types.LOGIN_OAUTH_FAILURE });
        } else {
            // exchangeToken expired, restart flow
            yield put(notificationActions.showNotification(errorMsg, "error", ["externalLayout"]));
            yield put({ type: globalTypes.BACK_TO_STEP_0 });
            yield put(replace("/"));
        }
    } else {
        // Ignored because it's the OAUTH standard
        // eslint-disable-next-line camelcase
        const { access_token, refresh_token } = response.data;
        if (!isFromMessenger && !isFromWhatsapp) {
            session.setAuthToken(access_token);
            yield put(sessionActions.setTokens(access_token, refresh_token));
        } else {
            yield put(assistantActions.setAccessToken(access_token));
            yield put(replace("/loginStep5"));
            return;
        }
        const environmentsResponse = yield call(session.listEnvironments);
        const {
            environments,
            // selectEnvironment,
            lang,
            _userFirstName,
            _userFullName,
            defEnvironmentEnabled,
        } = environmentsResponse.data.data;

        if (!defEnvironmentEnabled) {
            yield put(
                notificationActions.showNotification(i18n.get("settings.defaultEnvironment.blockedMessage"), "error", [
                    "loginStep3",
                ]),
            );
        }

        const rememberUser = yield select(selectors.getShouldRememberEmail);
        const rememberedUser = yield select(selectors.getRememberedUser);

        // Remember user
        if (rememberUser) {
            const userName = yield select(selectors.getUsername);
            yield put(
                loginActions.setRememberedUser({
                    username: userName,
                    userFirstName: _userFirstName,
                    userFullName: _userFullName,
                    isUserActiveCorporate: rememberedUser.isUserActiveCorporate,
                }),
            );
        }

        yield put({
            type: types.LOGIN_OAUTH_SUCCESS,
            lang,
            _userFirstName,
            _userFullName,
            environments,
        });

        yield put(replace("/loginStep3"));
    }
}

function* handleLoginStep3BiometricRequest({
    idEnvironment,
    rememberEnvironment,
    isUserActiveCorporate,
    secondFactor,
}) {
    const exchangeToken = yield select(selectors.getExchangeToken);
    const isFromMessenger = yield select(assistantSelectors.isFromMessenger);
    const isFromGoogle = yield select(assistantSelectors.isFromGoogle);
    const isFromAmazon = yield select(assistantSelectors.isFromAmazon);
    const isFromWhatsapp = yield select(assistantSelectors.isFromWhatsapp);
    const clientNumber = yield select(assistantSelectors.getClientNumber);
    const accountLinkingToken = yield select(assistantSelectors.getAccountLinkingToken);

    const location = {
        latitude: 0.0,
        longitude: 0.0,
    };

    let extraInfo = {};
    let idDevice = null;
    const registrationId = null;

    /* if (window.app) {
        const { registrationId: registrationIdResult } = yield call(window.pushNotifications.isEnabled);
        extraInfo = window.device;
        extraInfo.isTablet = false;
        extraInfo.isIOS = deviceUtils.getMobileOS(deviceUtils.getDisplay()) === deviceUtils.IOS_DEVICE;
        extraInfo.isAndroid = deviceUtils.getMobileOS(deviceUtils.getDisplay()) === deviceUtils.ANDROID_DEVICE;
        extraInfo.uuid = window.app.getDeviceUUID();
        registrationId = registrationIdResult;
        idDevice = window.app.getDeviceUUID();
    } else { */
    idDevice = yield deviceUtils.getDeviceFingerprint();
    extraInfo = yield deviceUtils.getExtraInfo();
    /* } */
    yield call(session.registerUserDevice, exchangeToken, idDevice, registrationId, JSON.stringify(extraInfo));

    // const _secondFactor = secondFactor;

    const response = yield call(
        session.loginStep3,
        exchangeToken,
        idEnvironment,
        rememberEnvironment,
        location,
        idDevice,
        {
            isFromMessenger,
            isFromGoogle,
            isFromAmazon,
            isFromWhatsapp,
            accountLinkingToken,
            clientNumber,
        },
        secondFactor,
    );

    if (response.type === "W") {
        const { code, data } = response.data;

        if (code === "COR020W" && data.secondFactor) {
            yield call(redirectGeneralLoginError, data.secondFactor);
            yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
            // formikBag.setErrors(data);
            yield put({ type: types.LOGIN_FAILURE });
            yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
            // formikBag.setSubmitting(false);
            // yield put(replace("/loginStep3"));
        } else if (code === "COR020W" && data.environment) {
            // mark the environment as disabled and redirecting the user to the step 3 again
            const environments = yield select(selectors.getEnvironments);
            if (environments[idEnvironment]) {
                environments[idEnvironment].allowedToAccess = false;
                yield put({ type: types.MARK_ENVIRONMENTS_DISABLED, environments });
            }

            //formikBag.setSubmitting(false);
            yield put(replace("/loginStep3"));
        } else if (code === "API707W") {
            yield put(notificationActions.showNotification(data.message, "error", ["externalLayout", "loginStep3"]));
            yield put({ type: types.LOGIN_FAILURE_USER_BLOQUED });
            yield put(replace("/userBlocked"));
        } else if (code === "API708W") {
            yield call(redirectGeneralLoginError,  i18n.get(
                "secondFactor.credential.otp.expired",
                "Código OTP expirado, solicite un nuevo código OTP",
            ));
            yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
            /* yield put(
                notificationActions.showNotification(
                    i18n.get(
                        "secondFactor.credential.otp.expired",
                        "Código OTP expirado, solicite un nuevo código OTP",
                    ),
                    "warning",
                    ["externalLayout", "loginStep3"],
                ),
            ); */
            yield put({ type: types.LOGIN_FAILURE });
            // formikBag.setSubmitting(false);
        } else {
            yield call(redirectGeneralLoginError, response.data.message);
            yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
        }
    } else {
        const {
            enabledAssistant,
            tourSkipsCounter,
            scheduledMigrationFinished,
            signatureLevel,
            statusModals,
            credentialType,
            otpMethod,
            email,
            mobileNumber,
            daysBeforeExpirePassword,
            notifyPasswordExpiration,
        } = response?.data?.data || {};
        
        yield put({ type: types.FINALIZE_LOGIN, response, isUserActiveCorporate });
        yield put(tourActions.setSkipsCounter(tourSkipsCounter));
        yield put({
            type: sessionTypes.UPDATE_PASSWORD_EXPIRATION_CONDITIONS,
            daysBeforeExpirePassword,
            notifyPasswordExpiration,
        });
        yield put({ type: sessionTypes.SET_ENABLED_ASSISTANT, enabledAssistant });
        yield put({ type: sessionTypes.SET_SIGNATURE_LEVEL, signatureLevel });
        yield put({ type: sessionTypes.SET_STATUS_MODALS, statusModals });
        yield put({ type: sessionTypes.SET_CREDENTIAL_TYPE, credentialType });
        yield put({ type: sessionTypes.SET_OTP_METHOD, otpMethod });
        yield put({ type: sessionTypes.SET_OTP_METHOD, otpMethod });
        yield put({ type: sessionTypes.SET_MEDIA_CONTACT, email, mobileNumber });
        // yield put({ type: sessionTypes.SET_PRODUCT_TYPES, productTypes });
        if (scheduledMigrationFinished) {
            yield put({ type: transactionTypes.SET_LOAD_MIGTRATION_EXECUTION_COUNT, loadMigrationExecutionCount: 3 });
        }
    }
}

function* handleLoginStep3VerificationRequest({
    formikBag,
    idEnvironment,
    rememberEnvironment,
    isUserActiveCorporate,
    secondFactor,
}) {
    const exchangeToken = yield select(selectors.getExchangeToken);
    const isFromMessenger = yield select(assistantSelectors.isFromMessenger);
    const isFromGoogle = yield select(assistantSelectors.isFromGoogle);
    const isFromAmazon = yield select(assistantSelectors.isFromAmazon);
    const isFromWhatsapp = yield select(assistantSelectors.isFromWhatsapp);
    const clientNumber = yield select(assistantSelectors.getClientNumber);
    const accountLinkingToken = yield select(assistantSelectors.getAccountLinkingToken);

    const location = {
        latitude: 0.0,
        longitude: 0.0,
    };

    let extraInfo = {};
    let idDevice = null;
    const registrationId = null;

    /* if (window.app) {
        const { registrationId: registrationIdResult } = yield call(window.pushNotifications.isEnabled);
        extraInfo = window.device;
        extraInfo.isTablet = false;
        extraInfo.isIOS = deviceUtils.getMobileOS(deviceUtils.getDisplay()) === deviceUtils.IOS_DEVICE;
        extraInfo.isAndroid = deviceUtils.getMobileOS(deviceUtils.getDisplay()) === deviceUtils.ANDROID_DEVICE;
        extraInfo.uuid = window.app.getDeviceUUID();
        registrationId = registrationIdResult;
        idDevice = window.app.getDeviceUUID();
    } else { */
    idDevice = yield deviceUtils.getDeviceFingerprint();
    extraInfo = yield deviceUtils.getExtraInfo();
    /* } */
    yield call(session.registerUserDevice, exchangeToken, idDevice, registrationId, JSON.stringify(extraInfo));

    // const _secondFactor = secondFactor;

    const response = yield call(
        session.loginStep3,
        exchangeToken,
        idEnvironment,
        rememberEnvironment,
        location,
        idDevice,
        {
            isFromMessenger,
            isFromGoogle,
            isFromAmazon,
            isFromWhatsapp,
            accountLinkingToken,
            clientNumber,
        },
        secondFactor,
    );

    if (response.type === "W") {
        const { code, data } = response.data;

        if (code === "COR020W" && data.secondFactor) {
            formikBag.setErrors(data);
            yield put({ type: types.LOGIN_FAILURE });
            yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
            formikBag.setSubmitting(false);
            // yield put(replace("/loginStep3"));
        } else if (code === "COR020W" && data.environment) {
            // mark the environment as disabled and redirecting the user to the step 3 again
            const environments = yield select(selectors.getEnvironments);
            if (environments[idEnvironment]) {
                environments[idEnvironment].allowedToAccess = false;
                yield put({ type: types.MARK_ENVIRONMENTS_DISABLED, environments });
            }

            formikBag.setSubmitting(false);
            yield put(replace("/loginStep3"));
        } else if (code === "API707W") {
            yield put(notificationActions.showNotification(data.message, "error", ["externalLayout", "loginStep3"]));
            yield put({ type: types.LOGIN_FAILURE_USER_BLOQUED });
            yield put(replace("/userBlocked"));
        } else if (code === "API708W") {
            yield put(
                notificationActions.showNotification(
                    i18n.get(
                        "secondFactor.credential.otp.expired",
                        "Código OTP expirado, solicite un nuevo código OTP",
                    ),
                    "warning",
                    ["externalLayout", "loginStep3"],
                ),
            );
            yield put({ type: types.LOGIN_FAILURE });
            formikBag.setSubmitting(false);
        } else {
            // en este paso lo unico que podria suceder es que se venciera el exchange token
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
            yield put(notificationActions.showNotification(response.data.message, "error", ["externalLayout"]));
            yield put({ type: globalTypes.BACK_TO_STEP_0 }); // por lo que borro lo que tengo de sesion y voy al step0

            formikBag.setSubmitting(false);
            yield put(replace("/"));
        }
    } else {
        const {
            enabledAssistant,
            tourSkipsCounter,
            scheduledMigrationFinished,
            signatureLevel,
            statusModals,
            credentialType,
            otpMethod,
            email,
            mobileNumber,
            daysBeforeExpirePassword,
            notifyPasswordExpiration,
        } = response?.data?.data || {};
        
        yield put({ type: types.FINALIZE_LOGIN, response, isUserActiveCorporate });
        yield put(tourActions.setSkipsCounter(tourSkipsCounter));
        yield put({
            type: sessionTypes.UPDATE_PASSWORD_EXPIRATION_CONDITIONS,
            daysBeforeExpirePassword,
            notifyPasswordExpiration,
        });
        yield put({ type: sessionTypes.SET_ENABLED_ASSISTANT, enabledAssistant });
        yield put({ type: sessionTypes.SET_SIGNATURE_LEVEL, signatureLevel });
        yield put({ type: sessionTypes.SET_STATUS_MODALS, statusModals });
        yield put({ type: sessionTypes.SET_CREDENTIAL_TYPE, credentialType });
        yield put({ type: sessionTypes.SET_OTP_METHOD, otpMethod });
        yield put({ type: sessionTypes.SET_OTP_METHOD, otpMethod });
        yield put({ type: sessionTypes.SET_MEDIA_CONTACT, email, mobileNumber });
        // yield put({ type: sessionTypes.SET_PRODUCT_TYPES, productTypes });
        if (scheduledMigrationFinished) {
            yield put({ type: transactionTypes.SET_LOAD_MIGTRATION_EXECUTION_COUNT, loadMigrationExecutionCount: 3 });
        }
    }
}

function* handleLoginStep4Request({ acceptConditions, formikBag }) {
    const activeRegion = yield select(selectors.getRegion);
    const exchangeToken = yield select(selectors.getExchangeToken);
    const username = yield select(selectors.getUsername);
    const idEnvironmentToAcceptConditions = yield select(selectors.getIdEnvironmentToAcceptConditions);
    const newEnvironments = yield select(selectors.getNewEnvironments);
    const response = yield call(session.loginStep4, exchangeToken, acceptConditions, idEnvironmentToAcceptConditions);

    if (response.type === "W") {
        if (formikBag) {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        }
        if (formikBag) {
            formikBag.setSubmitting(false);
        }
        yield put(notificationActions.showNotification(response.data.message, "error", ["loginStep4"]));
        yield put({ type: types.LOGIN_FAILURE, errors: response.data.data });
    } else {
        const loginData = yield call(processLoginSuccess, response);
        if (loginData.user.isNeedingUpdate) {
            yield put(desktopActions.updateModalShow(loginData.user.daysSinceLastUpdt));
        }
        const { data } = response.data;
        const { lastHref } = yield select((state) => state.status);

        configUtils.setRecaptchaLang(data.lang);
        yield put(i18nActions.setLang(data.lang));

        if (
            configUtils.get("core.sessionHandler.componentFQN") ===
            "com.technisys.omnichannel.core.session.DbSessionHandler"
        ) {
            yield call(session.setAuthToken, data._accessToken);
        } else {
            const responseOAuth = yield call(session.oauth, username, exchangeToken);

            if (responseOAuth.type === "W") {
                const errorMsg = i18n.get(
                    `returnCode.${responseOAuth.data.error_description}`,
                    responseOAuth.data.error_description,
                );
                yield put({ type: types.LOGIN_OAUTH_FAILURE });
                // exchangeToken expired, restart flow
                yield put(notificationActions.showNotification(errorMsg, "error", ["externalLayout"]));
                yield put({ type: globalTypes.BACK_TO_STEP_0 });
                yield put(replace("/"));
            } else {
                const { access_token, refresh_token } = responseOAuth.data;

                session.setAuthToken(access_token);
                yield put(sessionActions.setTokens(access_token, refresh_token));
            }
        }

        yield put({
            type: types.LOGIN_SUCCESS,
            environment: loginData.environment,
            user: loginData.user,
            environments: data.environments,
            isAdministrator: data.isAdministrator,
        });

        const { pepCompleted, irsCompleted } = loginData.user;

        if ((!pepCompleted || !irsCompleted) && activeRegion === REGION_USA) {
            yield put(replace("/pendingActions"));
        } else if (lastHref) {
            // si la sesion expiró y se guardó la url en la que se estaba voy para ahi
            yield put(replace(editLastHrefByConditions(lastHref, loginData.environment)));
            yield put(statusActions.deleteLastHref());
        } else if (newEnvironments && newEnvironments.length > 0) {
            yield put(replace("/newEnvironments"));
        } else {
            yield put(statusActions.resetComeFromLogin());
            // si no, voy a desktop
            yield put(replace("/desktop"));
        }
    }
}

function processLoginSuccess(response) {
    const {
        _accessToken,
        _securitySeal,
        username,
        userFirstName,
        userFullName,
        userId,
        previousLoginInfo,
        defaultAvatarId,
        email,
        clients,
        signedTAndCDate,
        irsDate,
        pepCompleted,
        irsCompleted,
        ssnid,
        extendedData,
        isNeedingUpdate,
        daysSinceLastUpdt,
        bankType,
        ...data
    } = response.data.data;

    const user = {
        defaultAvatarId,
        previousLoginInfo,
        userFirstName,
        userFullName,
        username,
        userId,
        email,
        accessToken: _accessToken,
        securitySeal: _securitySeal,
        signedTAndCDate,
        irsDate,
        pepCompleted,
        irsCompleted,
        ssnid,
        idDefaultEnvironment:
            data.idDefaultEnvironment && data.idDefaultEnvironment > 0 ? data.idDefaultEnvironment : null,
        isNeedingUpdate,
        daysSinceLastUpdt,
        bankType,
    };

    /**
     * Add mask amount user
     */
    if (extendedData) {
        const { productsMaskAmount } = extendedData;
        // user.maskAmount = maskAmount;
        user.productsMaskAmount = productsMaskAmount;
    }
    let forms = null;
    if (data.forms) {
        forms = {};

        for (let i = 0; i < data.forms.length; i++) {
            let category = forms[data.forms[i].category];
            if (!category) {
                category = [];
                forms[data.forms[i].category] = category;
            }
            category.push(data.forms[i]);
        }
    }

    const environment = {
        permissions: data.permissions,
        forms,
        name: data.activeEnvironmentName,
        type: data.activeEnvironmentType,
        id: data.activeIdEnvironment,
        administrationScheme: data.administrationScheme,
        clients,
    };

    return { user, environment };
}

function* getDeviceInfo() {
    if (!deviceUtils.isMobileNativeFunc()) {
        const idDevice = yield deviceUtils.getDeviceFingerprint();
        const extraInfo = yield deviceUtils.getExtraInfo();
        yield;
        return { idDevice, extraInfo: JSON.stringify(extraInfo) };
    }

    // const { registrationId: registrationIdResult } = yield call(window.pushNotifications.isEnabled);
    const extraInfo = window.device;
    extraInfo.isTablet = false;
    extraInfo.isIOS = deviceUtils.getMobileOS(deviceUtils.getDisplay()) === deviceUtils.IOS_DEVICE;
    extraInfo.isAndroid = deviceUtils.getMobileOS(deviceUtils.getDisplay()) === deviceUtils.ANDROID_DEVICE;
    extraInfo.uuid = window.app.getDeviceUUID();
    const idDevice = window.app.getDeviceUUID();
    yield;
    return { idDevice, extraInfo: JSON.stringify(extraInfo), registrationId: "" };
}

function* redirectTermConditionStep(response) {
    const {
        generalConditionsShowExpiration,
        generalConditionsText,
        generalConditionsExpirationDate,
        generalConditions,
    } = response?.data?.data;

    const activeRegion = yield select(selectors.getRegion);
    const termsAndConditions = {
        // just joining the long named variables into a single object
        showExpiration: generalConditionsShowExpiration,
        generalConditions,
        body: generalConditionsText,
        expirationDate: generalConditionsExpirationDate,
    };

    yield put({ type: types.LOGIN_STEP_3_SUCCESS, termsAndConditions });
    if (activeRegion && activeRegion === REGION_USA) {
        const firstName = yield select(selectors.getUserFirstName);
        const email = yield select(selectors.getUsername);
        yield put({
            type: enrollmentTypes.SET_INVITATION,
            invitation: {
                email,
                firstName,
                lastName: "",
            },
        });
        yield put(replace("/enrollment/Step3Part4"));
        yield;
        return;
    }

    yield put(replace("/loginStep4"));
}

function* redirectGeneralLoginError(errorMsg) {
    yield put({ type: types.LOGIN_OAUTH_FAILURE });
    // exchangeToken expired, restart flow
    yield put(notificationActions.showNotification(errorMsg || "", "error", ["externalLayout"]));
    yield put({ type: globalTypes.BACK_TO_STEP_0 });
    yield put(replace("/"));
}

function* handleBiometricLogin({ shouldRememberEmail }) {
    let fingerprintAuthToken = null;
    let fingerprintAuthTokenExists = true;

    const location = {
        latitude: 0.0,
        longitude: 0.0,
    };

    try {
        fingerprintAuthToken = yield call(secureStorageUtils.get, "fingerprintAuthToken");
    } catch (error) {
        fingerprintAuthTokenExists = false;
    }

    if (fingerprintAuthTokenExists) {
        try {
            yield call(fingerprintUtils.verify);
            const username = yield call(secureStorageUtils.get, "fingerprintUsername");
            const response = yield call(session.fingerprintOauth, username, fingerprintAuthToken);
          
            if (response.status === 200) {
                if (response.type === "W") {
                    const errorMsg = i18n.get(
                        `returnCode.${response?.data?.error_description || ""}`,
                        "login.error.default.message",
                    );
                    yield call(redirectGeneralLoginError, errorMsg);
                } else {
                    const { access_token, refresh_token } = response.data;

                    session.setAuthToken(access_token);
                    yield put(sessionActions.setTokens(access_token, refresh_token));

                    const deviceInfo = yield call(getDeviceInfo);

                    const responseFinger = yield call(session.fingerprintLogin, location, deviceInfo);

                    if (responseFinger?.data.code === "API704W") {
                        yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
                        yield put({ type: types.EXPIRED_PASSWORD, username });
                        yield put(replace("/expiredPassword"));
                        return;
                    }

                    if (responseFinger?.data?.code === "API605W") {
                        yield put({ type: types.LOGIN_FAILURE_USER_BLOQUED });
                        yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
                        yield put(replace("/userBlockedBank"));
                        return;
                    }

                    if (responseFinger?.data?.code === "API720W") {
                        yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
                        yield put(replace("/errorActiveSession"));
                        return;
                    }

                    if (!responseFinger?.type || !responseFinger?.data?.data) {
                        yield call(redirectGeneralLoginError, i18n.get("login.error.default.message"));
                        yield;
                        return;
                    }

                    if (responseFinger.type === "W") {
                        yield call(redirectGeneralLoginError, i18n.get("login.error.default.message"));
                        yield;
                        return;
                    }
                    // const { generalConditions } = responseFinger.data.data;

                    // if (generalConditions) {
                    //     yield call(redirectTermConditionStep, responseFinger);
                    //     yield;
                    //     return;
                    // }
                    const {
                        environments,
                        isAdministrator,
                        tourSkipsCounter,
                        enabledAssistant,
                        lang,
                        _exchangeToken,
                        userFirstName,
                        userFullName,
                        environment,
                        idDefaultEnvironment,
                        email,
                        mobileNumber,
                        _securitySeal,
                        _securitySealAlt,
                        // isFirstLogin,
                    } = responseFinger.data.data;

                    yield put(tourActions.setSkipsCounter(tourSkipsCounter));

                    yield put(i18nActions.setLang(lang));

                    const isActiveCorporate = yield select(sessionSelectors.isActiveCorporate);
                        // const shouldRememberEmail = yield select(selectors.getShouldRememberEmail);
                        if (shouldRememberEmail) {
                            yield put(
                                loginActions.setRememberedUser({
                                    username,
                                    userFirstName,
                                    userFullName,
                                    isUserActiveCorporate: isActiveCorporate,
                                }),
                            );
                        }
                   
                    console.log("login.js Determinando segundo factor de autenticacion desde step2: ");
                    try{
                        let hasTokenSeed = yield call(getLoginSecondFactorSeed);
                        const credentials = yield call(getLoginSecondFactor,"session.login.legacy.step3", _exchangeToken, hasTokenSeed);
                        if(!credentials){
                            yield call(redirectGeneralLoginError, "No tiene factor de autenticaci\u00F3n robusto para continuar");
                            yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
                            return;
                        }
                        console.log("login.js Credenciales obtenidas: ",credentials);
                        const credential = credentials[0];
                        console.log("login.js Credenciales obtenidas: credential: ",credential);
                        console.log("login.js Credenciales obtenidas: credential.credentialType: ",credential.credentialType);

                        yield put({
                            type: types.LOGIN_FINGERPRINT_DATA_SUCCESS,
                            username,
                            securitySeal: _securitySeal,
                            securitySealAlt: _securitySealAlt,
                            shouldRememberEmail,
                            credentialType: credential.credentialType,
                            otpMethod: credential.credentialMethod,
                            exchangeToken: _exchangeToken,
                            lang,
                            _userFirstName: userFirstName,
                            _userFullName: userFullName,
                            environments,
                            defEnvironment: idDefaultEnvironment || environment
                        });

                        switch (credential.credentialType) {
                            case secondFactorUtils.SECOND_FACTOR_CONST.SOFT_HARD_TOKEN:
                                let tokenStatus = yield call(getLoginSecondFactorStatus, _exchangeToken);
                                const isDigitalTokenMethod = credential.credentialMethod === secondFactorUtils.SECOND_FACTOR_METHOD_CONST.DIGITAL_TOKEN;
                                
                                if(isMobileNativeFunc() && (hasTokenSeed && tokenStatus === USER_TOKEN_STATUS_ACTIVE && isDigitalTokenMethod)){
                                    console.log("login.js Credenciales SOFT_HARD_TOKEN LOGIN_STEP_2_TRANSITORY_SUCCESS: "); 
                                    console.log("login.js Credenciales SOFT_HARD_TOKEN: ");  
                                    const tokenValue = yield call(getOtpCode);
                                    console.log("login.js Credenciales SOFT_HARD_TOKEN loginWithDigitalTokenRequest tokenValue: ", tokenValue);
                                    yield call(handleLoginStep3BiometricRequest, {idEnvironment: idDefaultEnvironment || environment, rememberEnvironment: "false", isUserActiveCorporate: true, secondFactor: tokenValue});
                                    console.log("login.js Credenciales SOFT_HARD_TOKEN Finalizando step2 y step3 : ", tokenValue);
                                }else{
                                    yield put({ type: sessionTypes.SET_MEDIA_CONTACT, email, mobileNumber });
                                    yield put(replace("/loginStep3"));
                                }
                                break; 
                            case secondFactorUtils.SECOND_FACTOR_CONST.SECURITY_QUESTIONS:
                                console.log("login.js Credenciales SECURITY_QUESTIONS: No se aplica mejora"); 
                                yield put({ type: sessionTypes.SET_MEDIA_CONTACT, email, mobileNumber });
                                yield put(replace("/loginStep3"));
                                break;  
                            case secondFactorUtils.SECOND_FACTOR_CONST.OTP:
                                console.log("login.js Credenciales OTP: Obteniendo codigo de verificacion desde step2: ");  
                                yield put({ type: sessionTypes.SET_MEDIA_CONTACT, email, mobileNumber });
                                yield put(secondFactorActions.secondFactorVerificationCodeRequest({ idActivity: "session.login.legacy.step3", exchangeToken: _exchangeToken }));
                                yield put(replace("/loginStep3"));
                                break;
                            default:
                                console.log("login.js Credenciales credential.credentialType NO DEFINIDO: Interrumpir flujo");
                                yield call(redirectGeneralLoginError, "No tiene factor de autenticaci\u00F3n robusto para continuar");
                                yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
                                return;
                                break;
                            }
                    } catch (error) {
                        console.log("Ha ocurrido un error al determinar el segundo factor en el step2/3: ", error);
                        yield call(redirectGeneralLoginError, "Ha ocurrido un error al determinar el segundo factor de autenticaci\u00F3n");
                        yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
                        return;
                    }

                    yield put({
                        type: types.LOGIN_FINGERPRINT_SUCCESS
                    });
                }
            }else if (response.status === 400) {
                if (response.type === "W") {
                    if (response?.data?.error_description === "COR123E") {
                        yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });  
                        yield put({ type: types.LOGIN_FAILURE_USER_BLOQUED });
                        yield put(replace("/userBlockedBank"));
                        yield;
                        return;
                    }
                
                    if (response?.data?.error_description === "COR121E") {
                        yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });  
                        yield put(notificationActions.showNotification(i18n.get(`returnCode.${response?.data?.error_description}`), "warning", ["externalLayout"]));
                        yield;
                        return;
                    }
                    if (response?.data?.error_description === "COR122E") {
                        yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });  
                        yield put(notificationActions.showNotification(i18n.get(`returnCode.${response?.data?.error_description}`), "warning", ["externalLayout"]));
                        yield;
                        return;
                    }
                    if (response?.data?.error_description === "COR047E") {
                        yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });  
                        yield put(notificationActions.showNotification(i18n.get(`returnCode.${response?.data?.error_description}`), "warning", ["externalLayout"]));
                        yield;
                        return;
                    }   
            }
                yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });          
            }
        } catch (error) {
            if (error?.data?.code === "COR019E") {
                yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
                yield put({ type: types.LOGIN_FAILURE_USER_BLOQUED });

                yield put(replace("/userBlocked"));
                return;
            }

            if (error?.data?.code === "COR005E") {
                yield put(replace("/"));
                yield put(notificationActions.showNotification(error.data.message, "warning", ["externalLayout"]));
            }

            if (error === fingerprintUtils.fingerprintErrors.FINGERPRINT_ERROR) {
                yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
                const mess = `${i18n.get("settings.fingerprintConfiguration.dialog.error_1")}\n${i18n.get(
                    "settings.fingerprintConfiguration.dialog.error_2",
                )}`;
                yield put(notificationActions.showNotification(mess, "error", ["externalLayout"]));
                yield;
                return;
            }

            if (error === fingerprintUtils.fingerprintErrors.FINGERPRINT_NOT_AVAILABLE) {
                yield put(replace("/"));
                yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
                const msg = `${i18n.get("settings.fingerprintConfiguration.passcode")}`;
                yield put(notificationActions.showNotification(msg, "error", ["externalLayout"]));
                yield;
                return;
            }

            if (error === fingerprintUtils.fingerprintErrors.FINGERPRINT_CANCELLED) {
                yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
                yield;
                return;
            }

            if (error.status && error.status === 200) {
                yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
                yield put(
                    notificationActions.showNotification(i18n.get("login.fingerprint.session.wrongAccess"), "error", [
                        "externalLayout",
                    ]),
                );
                yield;
                return;
            }

            if (error.response && error.response.status && error.response.status === 401) {
                yield put(
                    notificationActions.showNotification(i18n.get("login.fingerprint.session.expired"), "error", [
                        "externalLayout",
                    ]),
                );
                yield put({ type: globalTypes.BACK_TO_STEP_0 });
                yield put(replace("/"));
            }

            if (error?.code !== -128) {
                yield call(redirectGeneralLoginError, i18n.get("login.error.default.message"));
            }

            yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
            yield;
        }
    }
}

function* handleFingerprintLoginPre() {
    let fingerprintAuthToken = null;
    let fingerprintAuthTokenExists = true;

    const location = {
        latitude: 0.0,
        longitude: 0.0,
    };

    try {
        fingerprintAuthToken = yield call(secureStorageUtils.get, "fingerprintAuthToken");
    } catch (error) {
        fingerprintAuthTokenExists = false;
    }

    if (fingerprintAuthTokenExists) {
        const fingerPrintLoginFail = yield select((state) => state.session.fingerprintLoginFail);
        if (!fingerPrintLoginFail) {
            try {
                /**
                 * Set revelock position
                 */
                yield call(setPositionRevelock, "/login.biometric");
                yield call(fingerprintUtils.verify);

                const username = yield call(secureStorageUtils.get, "fingerprintUsername");
                const response = yield call(session.fingerprintOauth, username, fingerprintAuthToken);

                if (response.status === 200) {
                    yield put({ type: types.FINGERPRINT_LOGIN_SUBMIT });

                    // eslint-disable-next-line camelcase
                    if (response.type === "W") {
                        const errorMsg = i18n.get(
                            `returnCode.${response?.data?.error_description || ""}`,
                            "login.error.default.message",
                        );
                        yield call(redirectGeneralLoginError, errorMsg);
                    } else {
                        const { access_token, refresh_token } = response.data;

                        session.setAuthToken(access_token);
                        yield put(sessionActions.setTokens(access_token, refresh_token));

                        const deviceInfo = yield call(getDeviceInfo);
                        const responseFinger = yield call(session.fingerprintLogin, location, deviceInfo);
                        if (!responseFinger?.type || !responseFinger?.data?.data) {
                            yield call(redirectGeneralLoginError, i18n.get("login.error.default.message"));
                            yield;
                            return;
                        }

                        if (responseFinger.type === "W") {
                            yield call(redirectGeneralLoginError, i18n.get("login.error.default.message"));
                            yield;
                            return;
                        }
                        const { generalConditions } = responseFinger.data.data;

                        if (generalConditions) {
                            yield call(redirectTermConditionStep, responseFinger);
                            yield;
                            return;
                        }

                        yield call(fingerPrinterLoginSuccess, responseFinger);
                    }
                } else if (response.status === 400 && response.data?.error_description === "COR097E") {
                    yield put(
                        routerActions.push({
                            pathname: "serverError",
                        }),
                    );
                }
            } catch (error) {
                if (error === fingerprintUtils.fingerprintErrors.FINGERPRINT_ERROR) {
                    yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
                    const mess = `${i18n.get("settings.fingerprintConfiguration.dialog.error_1")}\n${i18n.get(
                        "settings.fingerprintConfiguration.dialog.error_2",
                    )}`;
                    yield put(notificationActions.showNotification(mess, "error", ["externalLayout"]));
                    yield;
                    return;
                }

                if (error === fingerprintUtils.fingerprintErrors.FINGERPRINT_CANCELLED) {
                    yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
                    yield;
                    return;
                }

                if (error.status && error.status === 200) {
                    yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
                    yield put(
                        notificationActions.showNotification(
                            i18n.get("login.fingerprint.session.wrongAccess"),
                            "error",
                            ["externalLayout"],
                        ),
                    );
                    yield;
                    return;
                }

                if (error.response && error.response.status && error.response.status === 401) {
                    yield put(
                        notificationActions.showNotification(i18n.get("login.fingerprint.session.expired"), "error", [
                            "externalLayout",
                        ]),
                    );
                    yield put({ type: globalTypes.BACK_TO_STEP_0 });
                    yield put(replace("/"));
                }

                yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
            }
        } else {
            yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
        }
    } else {
        yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
    }
}

function* showGenericErrorFingerPrinter(message, errors) {
    yield put(
        notificationActions.showNotification(message || i18n.get("login.error.default.message"), "error", [
            "loginStep4",
        ]),
    );
    yield put({ type: types.LOGIN_FAILURE, errors: errors || {} });
}

function* fingerPrinterLoginSuccess(responseFinger) {
    const {
        environments,
        isAdministrator,
        tourSkipsCounter,
        enabledAssistant,
        lang,
        // isFirstLogin,
    } = responseFinger.data.data;

    const { lastHref } = yield select((state) => state.status);

    yield put(tourActions.setSkipsCounter(tourSkipsCounter));
    yield put({ type: sessionTypes.SET_ENABLED_ASSISTANT, enabledAssistant });

    const loginData = yield call(processLoginSuccess, responseFinger);
    if (loginData.user.isNeedingUpdate) {
        yield put(desktopActions.updateModalShow(loginData.user.daysSinceLastUpdt));
    }

    configUtils.setRecaptchaLang(lang);
    yield put(i18nActions.setLang(lang));

    /**
     * Get session data
     */
    const { data: sessionData } = yield call(session.check);
    yield put({
        type: types.LOGIN_SUCCESS,
        environment: loginData.environment,
        user: { ...loginData.user, sessionId: sessionData?.data?.sessionId || "" },
        environments,
        isAdministrator,
    });

    /**
     * Set user id revelock
     */
    yield call(setUserRevelock, loginData?.user?.username, sessionData?.data?.sessionId);

    /**
     * Update mask amount
     */
    const { productsMaskAmount } = loginData.user;
    if (productsMaskAmount) {
        yield put(sessionActions.maskAmountUpdate(productsMaskAmount));
    }

    // if (!isFirstLogin) {
    //     yield put(productActions.syncEnviromentProduct(true));
    // }

    yield fork(readCreditCardList);
    yield fork(readCreditLineList);

    if (lastHref) {
        if (lastHref === "/tokenActivationStep1") {
            yield call(validateUserToken);
        } else {
            yield put(replace(editLastHrefByConditions(lastHref, loginData.environment)));
            yield put(statusActions.deleteLastHref());
        }
    } else {
        yield put(replace("/desktop"));
        yield put(statusActions.resetComeFromLogin());
    }
}

function* fingerPrinterFourthStepRequest({ acceptConditions, formikBag }) {
    const response = yield call(session.fingerPrinterFourthStepRequest, acceptConditions);
    if (!response?.type) {
        yield call(showGenericErrorFingerPrinter);
        yield;
        return;
    }

    if (!response?.data?.data) {
        yield call(showGenericErrorFingerPrinter);
        yield;
        return;
    }

    if (response.type === "W") {
        if (formikBag) {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
            formikBag.setSubmitting(false);
        }
        yield call(showGenericErrorFingerPrinter, response?.data?.message, response?.data?.data);
        yield;
        return;
    }
    yield call(fingerPrinterLoginSuccess, response);
}

function* validateBiometricMigration() {
    try {
        const currentCredentials = yield call(getCurrentCredentials);

        const credentialData = JSON.parse(currentCredentials);

        if (!credentialData?.code || credentialData.code !== RESPONSE_SUCCESS_CODE || !credentialData?.data) {
            yield;
            return;
        }

        if (!credentialData?.data?.username || !credentialData?.data?.token) {
            yield;
            return;
        }

        const lang = i18n?.getLang();
        const i18Configuration = {
            locale: lang ? lang + (lang === "en" ? "_US" : "") : "",
            title: i18n?.get("settings.fingerprintConfiguration.dialog.title") || "",
            message: i18n?.get("settings.fingerprintConfiguration.dialog.message") || "",
        };

        const resultFingerPrinter = yield call(
            requestFingerPrinterDecrypt,
            credentialData.data.username,
            credentialData.data.token,
            i18Configuration,
        );

        if (!resultFingerPrinter?.password) {
            yield;
            return;
        }

        const usernameResponse = yield call(decryptCredential, credentialData.data.username);
        const passwordResponse = yield call(decryptCredential, resultFingerPrinter.password);

        if (!usernameResponse || !passwordResponse) {
            yield;
            return;
        }

        const usernameData = JSON.parse(usernameResponse);
        const passwordData = JSON.parse(passwordResponse);

        if (!usernameData?.data || !passwordData?.data) {
            yield;
            return;
        }

        yield put(loginActions.fromBiometricMigrationRequest(true));
        yield put(
            loginActions.loginStep1(
                usernameData.data.trim(),
                false,
                passwordData.data.trim(),
                undefined,
                "password",
                undefined,
                undefined,
            ),
        );
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log("Error migrate biometric", e);
    }
}
function* removeNotifyEnvironments() {
    try {
        const { userId } = yield select(sessionSelectors.getUser);
        const response = yield call(session.removeNotifyEnvironments, userId);
        if (response.type === "W") {
            yield put(
                notificationActions.showNotification(i18n.get("login.fingerprint.session.expired"), "error", [
                    "externalLayout",
                ]),
            );
            yield put({ type: globalTypes.BACK_TO_STEP_0 });
            yield put(replace("/"));
        } else {
            yield put(replace("/desktop"));
            yield put(statusActions.resetComeFromLogin());
        }
    } catch (error) {
        if (error.response && error.response.status && error.response.status === 401) {
            yield put(
                notificationActions.showNotification(i18n.get("login.fingerprint.session.expired"), "error", [
                    "externalLayout",
                ]),
            );
            yield put({ type: globalTypes.BACK_TO_STEP_0 });
            yield put(replace("/"));
        }
    }
}

function* checkFingerprintValidSession() {
    let fingerprintAuthToken = null;
    let fingerprintAuthTokenExists = true;

    try {
        fingerprintAuthToken = yield call(secureStorageUtils.get, "fingerprintAuthToken");
        console.log("checkFingerprintValidSession fingerprintAuthToken: ", fingerprintAuthToken);
    } catch (error) {
        fingerprintAuthTokenExists = false;
    }

    if (fingerprintAuthTokenExists) {
        const fingerPrintLoginFail = yield select((state) => state.session.fingerprintLoginFail);
        if (!fingerPrintLoginFail) {
            try {
                // yield call(fingerprintUtils.verify);
                const username = yield call(secureStorageUtils.get, "fingerprintUsername");
                const response = yield call(session.fingerprintOauth, username, fingerprintAuthToken);
                console.log("checkFingerprintValidSession username response: ", username, response);

                if (response.status === 200) {
                    // eslint-disable-next-line camelcase
                    if (response.type === "W") {
                        yield put({ type: types.FINGERPRINT_CHECK_SESSION_FAILURE });
                        yield put(replace("/"));
                        yield put(
                            notificationActions.showNotification(
                                i18n.get("settings.fingerprintConfiguration.dialog.error"),
                                "error",
                                ["externalLayout"],
                            ),
                        );
                    } else {
                        const { access_token, refresh_token } = response.data;
                        // session.setAuthToken(access_token);
                        // yield put(sessionActions.setTokens(access_token, refresh_token));
                        // const deviceInfo = yield call(getDeviceInfo);
                        const responseFinger = yield call(session.checkFingerprintSession, access_token);
                        if (!responseFinger?.type || !responseFinger?.data?.data) {
                            yield put({ type: types.FINGERPRINT_CHECK_SESSION_FAILURE });
                            yield put(replace("/"));
                            yield put(
                                notificationActions.showNotification(
                                    i18n.get("settings.fingerprintConfiguration.dialog.error"),
                                    "error",
                                    ["externalLayout"],
                                ),
                            );
                        }

                        if (responseFinger.type === "W") {
                            yield put({ type: types.FINGERPRINT_CHECK_SESSION_FAILURE });
                            yield put(replace("/"));
                            yield put(
                                notificationActions.showNotification(
                                    i18n.get("settings.fingerprintConfiguration.dialog.error"),
                                    "error",
                                    ["externalLayout"],
                                ),
                            );
                            return;
                        }
                        const { existSessionWithFingerPrint } = responseFinger.data.data;
                        console.log("FINGERPRINT_CHECK_SESSION_SUCCESS: ", existSessionWithFingerPrint);

                        if (existSessionWithFingerPrint) {
                            yield put({
                                type: types.FINGERPRINT_CHECK_SESSION_SUCCESS,
                                existSessionWithFingerPrint,
                            });
                        } else {
                            yield put({
                                type: types.FINGERPRINT_CHECK_SESSION_SUCCESS,
                                existSessionWithFingerPrint: false,
                            });
                            yield put(replace("/"));
                            yield put(
                                notificationActions.showNotification(
                                    i18n.get("login.fingerprint.session.noExists"),
                                    "error",
                                    ["externalLayout"],
                                ),
                            );
                        }

                        // yield call(fingerPrinterLoginSuccess, responseFinger);
                    }
                } else if (response.status === 400) {
                    yield put({ type: types.FINGERPRINT_CHECK_SESSION_FAILURE });
                    yield put(replace("/"));
                    yield put(
                        notificationActions.showNotification(
                            i18n.get("settings.fingerprintConfiguration.dialog.error"),
                            "error",
                            ["softToken", "externalLayout", "login"],
                        ),
                    );
                }
                console.log("checkfingerprintsesssion response.status: ", response.status);
            } catch (error) {
                console.log("checkfingerprintsesssion: ", error);
                if (error === fingerprintUtils.fingerprintErrors.FINGERPRINT_ERROR) {
                    yield put({ type: types.FINGERPRINT_CHECK_SESSION_FAILURE });
                    const mess = `${i18n.get("settings.fingerprintConfiguration.dialog.error_1")}\n${i18n.get(
                        "settings.fingerprintConfiguration.dialog.error_2",
                    )}`;
                    yield put(replace("/"));
                    yield put(notificationActions.showNotification(mess, "error", ["externalLayout"]));
                    yield;
                    return;
                }

                if (error === fingerprintUtils.fingerprintErrors.FINGERPRINT_CANCELLED) {
                    yield put({ type: types.FINGERPRINT_CHECK_SESSION_FAILURE });
                    yield put(replace("/"));
                    yield put(
                        notificationActions.showNotification(
                            i18n.get("settings.fingerprintConfiguration.dialog.cancelled"),
                            "error",
                            ["externalLayout"],
                        ),
                    );
                    yield;
                    return;
                }

                if (error.status && error.status === 200) {
                    yield put({ type: types.FINGERPRINT_CHECK_SESSION_FAILURE });
                    yield put(replace("/"));
                    yield put(
                        notificationActions.showNotification(
                            i18n.get("login.fingerprint.session.check.error"),
                            "error",
                            ["externalLayout"],
                        ),
                    );
                    yield;
                    return;
                }

                if (error.response && error.response.status && error.response.status === 401) {
                    yield put(replace("/"));
                    yield put(
                        notificationActions.showNotification(
                            i18n.get("login.fingerprint.session.check.error"),
                            "error",
                            ["externalLayout"],
                        ),
                    );
                }

                yield put({ type: types.FINGERPRINT_CHECK_SESSION_FAILURE });
            }
        } else {
            yield put({ type: types.FINGERPRINT_CHECK_SESSION_FAILURE });
        }
    } else {
        yield put({ type: types.FINGERPRINT_CHECK_SESSION_FAILURE });
    }
}
function* getOtpCode() {
    const currentOtpResponse = yield call(generateOTP);
    if (!currentOtpResponse) {
        return;
    }
    const { code, data } = JSON.parse(currentOtpResponse);
    if (!code || !data || code !== ENT000) {
        return;
    }
    const valueToken = data;
    const valueTokenFormat = secondFactorUtils.getSecondFactorFormat(secondFactorUtils.SECOND_FACTOR_CONST.SOFT_HARD_TOKEN, valueToken);
    return valueTokenFormat;
};
