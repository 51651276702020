import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import * as creditCardmw from "middleware/creditCard";
import * as products from "middleware/products";
import { actions, types, selectors } from "reducers/creditCard";
import { actions as changeStatusProductActions } from "reducers/changeStatusProduct";
import {
    downloadPdf,
    downloadXls,
    downloadCSVFile,
    downloadMobileFile,
    CONTENT_TYPE_PDF_EXTENSION,
    CONTENT_TYPE_XLS_EXTENSION,
} from "util/download";
import { actions as notificationActions } from "reducers/notification";
import { adjustIdFieldErrors } from "util/form.js";
import * as i18n from "util/i18n";
import { push, replace } from "react-router-redux";
import { isMobileNativeFunc } from "util/device";
import b64toBlob from "b64-to-blob";
import * as creditCardsCache from "util/creditCardsCache";
import * as session from "middleware/session";
import * as config from "util/config";
import * as deviceUtils from "util/device";
// import { getAllowedAdditionalCards } from "util/creditCards";

const sagas = [
    takeLatest(types.DETAILS_REQUEST, detailsRequest),
    takeLatest(types.READ_REQUEST, readCreditCardRequest),
    takeLatest(types.DETAILS_NO_MOVEMENTS_REQUEST, detailsNoMovementsRequest),
    takeLatest(types.MOVEMENTS_REQUEST, movementsRequest),
    takeLatest(types.FETCH_MORE_MOVEMENTS_REQUEST, fetchMoreMovementsRequest),
    takeLatest(types.DOWNLOAD_MOVEMENTS_REQUEST, downloadMovements),
    takeLatest(types.DOWNLOAD_DEFERRED_REQUEST, downloadDeferred),
    takeLatest(types.DOWNLOAD_ADDITIONALS_REQUEST, downloadAdditionals),
    takeEvery(types.DETAILS_EVERY_REQUEST, detailsEveryRequest),
    takeEvery(types.MOVEMENTS_TRANSIT_REQUEST, getMovementsTransit),
    takeEvery(types.DOWNLOAD_MOVEMENTS_TRANSIT_REQUEST, downloadMovementTransitList),
    takeEvery(types.ASSIGN_PIN_CREDIT_CARD, assignPinCode),
    takeEvery(types.FINANCING_PLANS_REQUEST, financingPlansRequest),
    takeLatest(types.LIST_ADDITIONAL_REQUEST, creditCardAdditionalRequest),
    takeLatest(types.OPEN_TC_STATUS_SAT_SERVICES_REQUEST, openTCStatusSATServices),
];

export default sagas;

function* detailsEveryRequest({ id }) {
    if (creditCardsCache.isCreditCardDataCacheStatusOk(id)) {
        const creditCardData = yield select(selectors.getDetail, id);
        const totalFavorites = yield select(selectors.getTotalFavorites);

        const creditCardSuccess = {
            ...creditCardData,
            hasErrorLoadingPoints: false,
        };

        yield put(actions.detailEverySuccess(id, creditCardSuccess, totalFavorites));
    } else {
        const detailResponse = yield call(creditCardmw.detailsRequest, id);

        if (detailResponse.type === "W") {
            const creditCardFailed = {
                idProduct: id,
                hasErrorLoadingPoints: true,
            };
            yield put(actions.detailEveryFailure(id, creditCardFailed));
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "creditCardDetails",
                ]),
            );
        } else {
            const { creditCard, totalFavorites } = detailResponse.data.data;

            const creditCardSuccess = {
                ...creditCard,
                hasErrorLoadingPoints: false,
            };

            yield put(actions.detailEverySuccess(id, creditCardSuccess, totalFavorites));
        }
    }
}

function* detailsNoMovementsRequest({ id, relationType, hasOwnLimit, idForm }) {
    if (creditCardsCache.isCreditCardDataCacheStatusOk(id)) {
        const creditCard = yield select(selectors.getDetail, id);
        const totalFavorites = yield select(selectors.getTotalFavorites);

        const updateCreditCard = { ...creditCard, hasOwnLimit, relationType };

        yield put(actions.detailSuccess(updateCreditCard, idForm, totalFavorites));
    } else {
        const detailResponse = yield call(creditCardmw.detailsRequest, id);

        if (detailResponse?.data?.code === "BAK001W") {
            yield put(
                push({
                    pathname: "/error",
                    code: detailResponse?.data?.code,
                    message: detailResponse?.data?.message,
                    idTransaction: detailResponse?.data?.idTransaction,
                }),
            );
        } else if (detailResponse.type === "W") {
            yield put(actions.detailFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "creditCardDetails",
                ]),
            );
        } else {
            const { creditCard, totalFavorites } = detailResponse.data.data;
            const updateCreditCard = creditCard ? { ...creditCard, hasOwnLimit, relationType } : creditCard;

            yield put(actions.detailSuccess(updateCreditCard, idForm, totalFavorites));
        }
    }
}

function* detailsRequest({ id, filters, relationType, hasOwnLimit, updatingByPoints, idForm }) {
    if (creditCardsCache.isCreditCardDataCacheStatusOk(id)) {
        const creditCard = yield select(selectors.getDetail, id);
        const detailInList = yield select(selectors.getDetailInList, id);
        const totalFavorites = yield select(selectors.getTotalFavorites);

        const updateCreditCard = {
            ...creditCard,
            hasOwnLimit,
            relationType,
            productionStatus: detailInList?.productionStatus,
        };

        yield put(actions.detailSuccess(updateCreditCard, idForm, totalFavorites));
        yield call(getMovements, id, filters, updatingByPoints);
    } else {
        const detailResponse = yield call(creditCardmw.detailsRequest, id);

        if (detailResponse?.data?.code === "BAK001W") {
            yield put(
                push({
                    pathname: "/error",
                    code: detailResponse?.data?.code,
                    message: detailResponse?.data?.message,
                    idTransaction: detailResponse?.data?.idTransaction,
                }),
            );
        } else if (detailResponse.type === "W") {
            yield put(actions.detailFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "creditCardDetails",
                ]),
            );
        } else {
            const { creditCard, totalFavorites } = detailResponse.data.data;
            const updateCreditCard = creditCard ? { ...creditCard, hasOwnLimit, relationType } : creditCard;
            yield put(actions.detailSuccess(updateCreditCard, idForm, totalFavorites));

            yield call(getMovements, id, filters, updatingByPoints);
        }
    }
}

function* getMovements(id, filters, updatingByPoints) {
    const movementsResponse = yield call(creditCardmw.movementsRequest, id, filters);

    if (movementsResponse.type === "W") {
        yield put(actions.detailFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["creditCardDetails"]),
        );
    } else {
        const {
            statements,
            pageNumber,
            moreStatements,
            totalCount,
            totalCurrentPeriod,
            offset,
        } = movementsResponse.data.data;

        yield put(
            actions.detailSuccessMovement(
                statements,
                pageNumber,
                moreStatements,
                totalCount,
                totalCurrentPeriod,
                offset,
            ),
        );

        if (updatingByPoints) {
            yield put(
                notificationActions.showNotification(i18n.get("forms.product.exchange.points.sent.succes"), "success", [
                    "creditCardDetails",
                ]),
            );
        }
    }
}

function* movementsRequest({ id, filters, formikBag }) {
    const response = yield call(creditCardmw.movementsRequest, id, filters);

    if (response.type === "W" && formikBag) {
        formikBag.setError(adjustIdFieldErrors(response.data.data));
    } else if (response.type === "W") {
        yield put(actions.movementsFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["creditCardDetails"]),
        );
    } else {
        const { statements, moreStatements, totalCount, totalCurrentPeriod } = response.data.data;
        yield put(actions.movementsSuccess(statements, moreStatements, totalCount, totalCurrentPeriod));
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* fetchMoreMovementsRequest({ id, filters, formikBag }) {
    const response = yield call(creditCardmw.movementsRequest, id, filters);

    if (response.type === "W" && formikBag) {
        formikBag.setError(adjustIdFieldErrors(response.data.data));
    } else if (response.type === "W") {
        yield put(actions.fetchMoreMovementsFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["creditCardDetails"]),
        );
    } else {
        const { statements, moreStatements, totalCount, totalCurrentPeriod, offset, quantity } = response.data.data;

        yield put(
            actions.fetchMoreMovementsSuccess(
                statements,
                moreStatements,
                totalCount,
                totalCurrentPeriod,
                filters.pageNumber,
                offset,
                quantity,
            ),
        );
    }

    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* errorDownloadMovements(onFinishDownload) {
    yield put({ type: types.DOWNLOAD_MOVEMENTS_FAILURE });
    yield put(
        notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["creditCard/details"]),
    );
    onFinishDownload();
}

function* errorDownloadDeferred(onFinishDownload) {
    yield put({ type: types.DOWNLOAD_DEFERRED_FAILURE });
    yield put(
        notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["creditCard/details"]),
    );
    onFinishDownload();
}

function* errorDownloadAdditionals(onFinishDownload) {
    yield put({ type: types.DOWNLOAD_ADDITIONALS_FAILURE });
    yield put(
        notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["creditCard/details"]),
    );
    onFinishDownload();
}

function* successDownloadMovements(onFinishDownload) {
    yield put({ type: types.DOWNLOAD_MOVEMENTS_SUCCESS });
    onFinishDownload();
}

function* successDownloadDeferred(onFinishDownload) {
    yield put({ type: types.DOWNLOAD_DEFERRED_SUCCESS });
    onFinishDownload();
}

function* successDownloadAdditionals(onFinishDownload) {
    yield put({ type: types.DOWNLOAD_ADDITIONALS_SUCCESS });
    onFinishDownload();
}

function* downloadFileOnMobile(content, contentType, fileName, onFinishDownload) {
    const fileBlob = b64toBlob(content, contentType);
    downloadMobileFile(fileBlob, fileName, contentType);
    yield call(successDownloadMovements, onFinishDownload);
}

function* downloadFileOnWeb(content, format, fileName, onFinishDownload, typeDownload) {
    if (typeDownload == "Movement") {
        if (format === "pdf") {
            downloadPdf(fileName, content);
            yield call(successDownloadMovements, onFinishDownload);
            yield;
            return;
        }

        if (format === "xls") {
            downloadXls(fileName, content);
            yield call(successDownloadMovements, onFinishDownload);
            yield;
            return;
        }

        if (format === "txt") {
            downloadCSVFile(fileName, content);
            yield call(successDownloadMovements, onFinishDownload);
            yield;
            return;
        }

        yield call(errorDownloadMovements, onFinishDownload);
    }
    if (typeDownload == "Deferred") {
        if (format === "pdf") {
            downloadPdf(fileName, content);
            yield call(successDownloadDeferred, onFinishDownload);
            yield;
            return;
        }

        if (format === "xls") {
            downloadXls(fileName, content);
            yield call(successDownloadDeferred, onFinishDownload);
            yield;
            return;
        }

        if (format === "txt") {
            downloadCSVFile(fileName, content);
            yield call(successDownloadDeferred, onFinishDownload);
            yield;
            return;
        }

        yield call(errorDownloadDeferred, onFinishDownload);
    }
    if (typeDownload == "Additionals") {
        if (format === "pdf") {
            downloadPdf(fileName, content);
            yield call(successDownloadAdditionals, onFinishDownload);
            yield;
            return;
        }

        if (format === "xls") {
            downloadXls(fileName, content);
            yield call(successDownloadAdditionals, onFinishDownload);
            yield;
            return;
        }

        if (format === "txt") {
            downloadCSVFile(fileName, content);
            yield call(successDownloadAdditionals, onFinishDownload);
            yield;
            return;
        }

        yield call(errorDownloadAdditionals, onFinishDownload);
    }
}

function* downloadMovements({ idCreditCard, filters, format, onFinishDownload, creditCard }) {
    const response = yield call(creditCardmw.downloadMovements, idCreditCard, filters, format, creditCard);
    if (response.type === "W" || !response?.data?.data) {
        yield call(errorDownloadMovements, onFinishDownload);
        yield;
        return;
    }

    const { content, fileName, contentType } = response.data.data;
    if (!content || !contentType) {
        yield call(errorDownloadMovements, onFinishDownload);
        yield;
        return;
    }

    if (isMobileNativeFunc()) {
        yield call(downloadFileOnMobile, content, contentType, fileName || "file", onFinishDownload);
        yield;
        return;
    }

    yield call(downloadFileOnWeb, content, format, fileName || "file", onFinishDownload, "Movement");
}

function* downloadDeferred({ idCreditCard, filters, format, onFinishDownload, creditCard }) {
    const response = yield call(creditCardmw.downloadDeferred, idCreditCard, filters, format, creditCard);
    if (response.type === "W" || !response?.data?.data) {
        yield call(errorDownloadDeferred, onFinishDownload);
        yield;
        return;
    }

    const { content, fileName, contentType } = response.data.data;
    if (!content || !contentType) {
        yield call(errorDownloadDeferred, onFinishDownload);
        yield;
        return;
    }

    if (isMobileNativeFunc()) {
        yield call(downloadFileOnMobile, content, contentType, fileName || "file", onFinishDownload);
        yield;
        return;
    }

    yield call(downloadFileOnWeb, content, format, fileName || "file", onFinishDownload, "Deferred");
}

function* downloadAdditionals({ idCreditCard, filters, format, onFinishDownload, creditCard }) {
    const response = yield call(creditCardmw.downloadAdditionals, idCreditCard, filters, format, creditCard);
    if (response.type === "W" || !response?.data?.data) {
        yield call(errorDownloadAdditionals, onFinishDownload);
        yield;
        return;
    }

    const { content, fileName, contentType } = response.data.data;
    if (!content || !contentType) {
        yield call(errorDownloadAdditionals, onFinishDownload);
        yield;
        return;
    }

    if (isMobileNativeFunc()) {
        yield call(downloadFileOnMobile, content, contentType, fileName || "file", onFinishDownload);
        yield;
        return;
    }

    yield call(downloadFileOnWeb, content, format, fileName || "file", onFinishDownload, "Deferred");
}

function* getMovementsTransit({ productId }) {
    const response = yield call(creditCardmw.getMovementsTransit, productId);
    if (!response) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop", "login"]),
        );
        yield put({ type: types.MOVEMENTS_TRANSIT_FAILURE });
    } else {
        const { type } = response;
        if (type === "W") {
            yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop"]));
            yield put(replace("/desktop"));
            yield put({ type: types.MOVEMENTS_TRANSIT_FAILURE });
        } else {
            yield put({
                type: types.MOVEMENTS_TRANSIT_SUCCESS,
                payload: { movementTransitList: response?.data?.data?.movementTransitList || [] },
            });
        }
    }
}

function* downloadMovementTransitList({ productId, contentType }) {
    const response = yield call(creditCardmw.downloadMovementsTransit, productId, contentType);
    if (!response) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop", "login"]),
        );
        yield put({ type: types.MOVEMENTS_TRANSIT_FAILURE });
    } else {
        const { type } = response;
        if (type === "W") {
            yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop"]));
            yield put(replace("/desktop"));
            yield put({ type: types.MOVEMENTS_TRANSIT_FAILURE });
        } else {
            if (response?.data?.data) {
                const { content, fileName, contentType: contentTypeResponse } = response.data.data;
                if (isMobileNativeFunc()) {
                    const fileBlob = b64toBlob(content, contentTypeResponse);
                    downloadMobileFile(fileBlob, fileName, contentTypeResponse);
                } else {
                    if (contentType === CONTENT_TYPE_PDF_EXTENSION) {
                        downloadPdf(fileName, content);
                    }
                    if (contentType === CONTENT_TYPE_XLS_EXTENSION) {
                        downloadXls(fileName, content);
                    }
                }
            }

            yield put({
                type: types.DOWNLOAD_MOVEMENTS_TRANSIT_SUCCESS,
            });
        }
    }
}

function* assignPinCode({ data }) {
    const { parametersSubmit, credentialsForm, formikBag, idActivity, paramsNotification } = data;
    const { pinCode } = credentialsForm;

    if (pinCode.length < 4) {
        yield put(changeStatusProductActions.modalHide());
        yield put(
            notificationActions.showNotification(
                i18n.get("creditCards.snackbar.assignPin.incomplete.messageError", null, paramsNotification?.error),
                "error",
                ["creditcards", "creditCardDetails", "desktop"],
            ),
        );
    }
    const response = yield call(products.changeProductStatus, idActivity, parametersSubmit, credentialsForm);

    if (response?.status === 200) {
        if (response.data.code === "COR020W") {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        } else if (response.data.code === "COR000I") {
            yield put(changeStatusProductActions.modalHide());
            yield put(
                notificationActions.showNotification(
                    i18n.get("creditCards.snackbar.assignPin.messageOk", null, paramsNotification?.success),
                    "success",
                    ["creditcards", "creditCardDetails", "desktop"],
                ),
            );
        } else {
            yield put(changeStatusProductActions.modalHide());
            yield put(
                notificationActions.showNotification(
                    i18n.get("creditCards.snackbar.assignPin.messageError", null, paramsNotification?.error),
                    "error",
                    ["creditcards", "creditCardDetails", "desktop"],
                ),
            );
        }
    } else {
        yield put(changeStatusProductActions.modalHide());
        yield put(
            notificationActions.showNotification(response.data.message, "error", [
                "creditcards",
                "creditCardDetails",
                "desktop",
            ]),
        );
    }
    if (formikBag) {
        formikBag.setSubmitting(false);
    }
}

function* financingPlansRequest({ productId, onFinish }) {
    const response = yield call(creditCardmw.getFinancingPlans, productId);
    if (!response) {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                "creditCardDetailFinancingPlan",
            ]),
        );
    } else {
        const { type } = response;
        if (type === "W") {
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "creditCardDetailFinancingPlan",
                ]),
            );
            yield put(replace("/desktop"));
        } else {
            yield put({
                type: types.FINANCING_PLANS_SUCCESS,
                financingPlans: response?.data?.data?.financingPlans || [],
            });
        }
    }

    if (onFinish) {
        onFinish();
    }
}

function* creditCardAdditionalRequest({ productId, typeOperation }) {
    const response = yield call(creditCardmw.creditCardAdditionalRequest, productId, typeOperation);
    if (response.type === "W") {
        yield put(actions.additionalCreditCardFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["creditCardDetails"]),
        );
    } else {
        const { creditCards } = response.data.data;
        // el servicio de tarjetas adicionales trae en null codeMatrixPermissions
        // const primaryCreditCard =   yield select(selectors.getDetailInList, productId);
        // const allowedAdditionalCards = getAllowedAdditionalCards(primaryCreditCard, creditCards);
        yield put(actions.additionalCreditCardSuccess(creditCards));
    }
}

export function* openTCStatusSATServices({ onFinish, productDataTC }) {
    try {
        const response = yield call(session.getBankToken, "ESTADO_TC", productDataTC);
        const { data } = response;
        if (data?.code === "COR000I") {
            const { token } = response.data.data;

            const urlTCStatusSAT = config.get("menu.creditCardStatusStatementServicesSAT.url");

            if (!urlTCStatusSAT) {
                yield put({
                    type: types.OPEN_TC_STATUS_SERVICES_FAILURE,
                });

                yield put(
                    notificationActions.showNotification(
                        i18n.get("desktop.opencreditCardStatusStatementSATServices.error"),
                        "error",
                        ["creditCard/details"],
                    ),
                );
                return;
            }

            yield put({
                type: types.OPEN_TC_STATUS_SERVICES_SUCCESS,
            });

            const url = urlTCStatusSAT.replace("%PARAM_TOKEN%", token);

            if (
                deviceUtils.getMobileOS(deviceUtils.getDisplay()) === deviceUtils.IOS_DEVICE ||
                deviceUtils.getMobileOS(deviceUtils.getDisplay()) === deviceUtils.ANDROID_DEVICE
            ) {
                // eslint-disable-next-line no-undef
                cordova.InAppBrowser.open(url, "_blank", "location=yes");
            } else {
                yield call(() => window.open(url, "_blank"));
            }
        } else {
            console.log("Error al invocar servicio token ***");
            yield put({
                type: types.OPEN_TC_STATUS_SERVICES_FAILURE,
            });
            if (data?.code === "BAK001W") {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("desktop.opencreditCardStatusStatementSATServices.error"),
                        "error",
                        ["creditCard/details"],
                    ),
                );
            }
        }
    } catch (e) {
        console.log("Error micrositio TC: ", e);
        yield put({
            type: types.OPEN_TC_STATUS_SERVICES_FAILURE,
        });

        const errorMessage = i18n.get("creditaCard.digitalAccountStatement.error");
        yield put(notificationActions.showNotification(errorMessage, "warning", ["creditCard/details"]));
    }
    if (onFinish) {
        onFinish();
    }
}

function* readCreditCardRequest({ creditCardId }) {
    const detailResponse = yield call(creditCardmw.detailsRequest, creditCardId);

    if (detailResponse?.data?.code === "BAK001W") {
        yield put(
            push({
                pathname: "/error",
                code: detailResponse?.data?.code,
                message: detailResponse?.data?.message,
                idTransaction: detailResponse?.data?.idTransaction,
            }),
        );
    } else if (detailResponse.type === "W") {
        yield put(actions.detailFailure());
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["creditCardDetails"]),
        );
    } else {
        const { creditCard } = detailResponse.data.data;
        const detailInList = yield select(selectors.getDetailInList, creditCardId);
        const creditCardDetail = {
            ...creditCard,
            idProduct: detailInList?.idProduct,
            productionStatus: detailInList?.productionStatus,
            balanceCurrency: detailInList?.balanceCurrency,
            minimumPaymentCurrency: detailInList?.balanceCurrency,
            currentDueCurrency: detailInList?.currentDueCurrency,
            productType: detailInList?.productType,
            label: detailInList?.label,
            shortLabel: detailInList?.shortLabel,
            numberMask: detailInList?.numberMask,
            isAditional: detailInList?.isAditional,
            hasExpirated: detailInList?.hasExpirated,
            cardStyle: detailInList?.cardStyle,
        };
        yield put(actions.readCreditCardSuccess(creditCardDetail));
    }
}
