import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { createReducer, makeActionCreator } from "util/redux";

import globalTypes from "reducers/types/global";
import { check } from "middleware/session";

export const types = {
    CHECKBOOKS_LIST_REQUEST: "checks/CHECKBOOKS_LIST_REQUEST",
    CHECKBOOKS_LIST_FAILURE: "checks/CHECKBOOKS_LIST_FAILURE",
    CHECKBOOKS_LIST_SUCCESS: "checks/CHECKBOOKS_LIST_SUCCESS",
    CHECKS_LIST_REQUEST: "checks/CHECKS_LIST_REQUEST",
    CHECKS_LIST_FAILURE: "checks/CHECKS_LIST_FAILURE",
    CHECKS_LIST_SUCCESS: "checks/CHECKS_LIST_SUCCESS",
    ALL_CHECKS_LIST_REQUEST: "checks/ALL_CHECKS_LIST_REQUEST",
    ALL_CHECKS_LIST_FAILURE: "checks/ALL_CHECKS_LIST_FAILURE",
    ALL_CHECKS_LIST_SUCCESS: "checks/ALL_CHECKS_LIST_SUCCESS",
    ALL_CHECKS_INIT: "checks/ALL_CHECKS_INIT",
    URL_CHECK_REQUEST: "checks/URL_CHECK_REQUEST",
    URL_CHECK_CLEAR: "checks/URL_CHECK_CLEAR",
    DATA_CHECK_SUSPEND: "checks/DATA_CHECK_SUSPEND",
    LOAD_MORE_REQUEST: "checks/LOAD_MORE_REQUEST",
    LOAD_MORE_FAILURE: "checks/LOAD_MORE_FAILURE",
    LOAD_MORE_SUCCESS: "checks/LOAD_MORE_SUCCESS",
    DOWNLOAD_CHECKS_REQUEST: "checks/DOWNLOAD_CHECKS_REQUEST",
    DOWNLOAD_CHECKS_FAILURE: "checks/DOWNLOAD_CHECKS_FAILURE",
    DOWNLOAD_CHECKS_SUCCESS: "checks/DOWNLOAD_CHECKS_SUCCESS",
    IMAGE_CHECK_REQUEST: "checks/IMAGE_CHECK_REQUEST",
    IMAGE_CHECK_CLEAR: "checks/IMAGE_CHECK_CLEAR",
    IMAGE_CHECK_SUCCESS: "checks/IMAGE_CHECK_SUCCESS",
};

export const INITIAL_STATE = {
    checkbooks: [],
    checks: [],
    currentCheckbook: {},
    accountNumber: "",
    fetching: false,
    initialCheckNumber: 0,
    finalCheckNumber: 0,
    fetchCheckUrl: false,
    dataFormToSuspend: {},
    pageNumber: 1,
    offset: 0,
    totalCount: 0,
    totalPages: 0,
    documentImage: {},
};

const reducer = createReducer(INITIAL_STATE, {
    [globalTypes.CLEAN_UP]: () => ({
        ...INITIAL_STATE,
    }),
    [types.CHECKBOOKS_LIST_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.CHECKBOOKS_LIST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.CHECKBOOKS_LIST_SUCCESS]: (state, action) => ({
        ...state,
        checkbooks: action.checkbooks,
        accountNumber: action.accountNumber,
        fetching: false,
    }),
    [types.CHECKS_LIST_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.CHECKS_LIST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.CHECKS_LIST_SUCCESS]: (state, action) => ({
        ...state,
        checks: action.checks,
        accountNumber: action.accountNumber,
        initialCheckNumber: action.initialCheckNumber,
        finalCheckNumber: action.finalCheckNumber,
        currentCheckbook: action.currentCheckbook,
        fetching: false,
    }),
    [types.ALL_CHECKS_LIST_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.ALL_CHECKS_LIST_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.ALL_CHECKS_LIST_SUCCESS]: (state, action) => ({
        ...state,
        checks: action.checks,
        accountNumber: action.idAccount,
        pageNumber: action.pageNumber,
        totalCount: action.totalCount,
        offset: action.offset,
        totalPages: action.totalPages,
        fetching: false,
    }),
    [types.ALL_CHECKS_INIT]: (state) => ({
        ...state,
        checks: [],
        fetching: false,
        offset: 0,
        totalCount: 0,
        totalPages: 0,
        documentImage: {},
    }),
    [types.URL_CHECK_REQUEST]: (state) => ({
        ...state,
        fetchCheckUrl: true,
    }),
    [types.URL_CHECK_CLEAR]: (state) => ({
        ...state,
        fetchCheckUrl: false,
    }),
    [types.DATA_CHECK_SUSPEND]: (state, action) => ({
        ...state,
        dataFormToSuspend: action.data,
    }),
    [types.LOAD_MORE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.LOAD_MORE_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.LOAD_MORE_SUCCESS]: (state, action) => ({
        ...state,
        checks: action.checks,
        accountNumber: action.idAccount,
        pageNumber: action.pageNumber,
        totalCount: action.totalCount,
        offset: action.offset,
        totalPages: action.totalPages,
        fetching: false,
    }),
    [types.DOWNLOAD_CHECKS_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.DOWNLOAD_CHECKS_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.DOWNLOAD_CHECKS_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.IMAGE_CHECK_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.IMAGE_CHECK_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        documentImage: action.documentImage,
    }),
});

export default persistReducer(
    {
        key: "checks",
        storage: storageSession,
        blacklist: ["fetching", "fetchCheckUrl"],
    },
    reducer,
);

export const actions = {
    listCheckbooksRequest: makeActionCreator(types.CHECKBOOKS_LIST_REQUEST, "idAccount"),
    listCheckbooksFailure: makeActionCreator(types.CHECKBOOKS_LIST_FAILURE),
    listCheckbooksSuccess: makeActionCreator(types.CHECKBOOKS_LIST_SUCCESS, "checkbooks", "accountNumber"),
    listChecksRequest: makeActionCreator(types.CHECKS_LIST_REQUEST, "idAccount", "idCheckbook"),
    listChecksFailure: makeActionCreator(types.CHECKS_LIST_FAILURE),
    listChecksSuccess: makeActionCreator(
        types.CHECKS_LIST_SUCCESS,
        "checks",
        "accountNumber",
        "initialCheckNumber",
        "finalCheckNumber",
        "currentCheckbook",
    ),
    listAllChecksRequest: makeActionCreator(
        types.ALL_CHECKS_LIST_REQUEST,
        "idAccount",
        "typeFilter",
        "startDate",
        "endDate",
        "amountStart",
        "amountEnd",
        "checkNumber",
        "nextIndex",
    ),
    listAllChecksFailure: makeActionCreator(types.ALL_CHECKS_LIST_FAILURE),
    listAllChecksSuccess: makeActionCreator(
        types.ALL_CHECKS_LIST_SUCCESS,
        "checks",
        "idAccount",
        "totalCount",
        "offset",
        "pageNumber",
        "totalPages",
    ),
    getUrlCheckRequest: makeActionCreator(types.URL_CHECK_REQUEST, "idAccount", "checkNumber"),
    getUrlCheckClear: makeActionCreator(types.URL_CHECK_CLEAR),
    setdataCheckToSuspend: makeActionCreator(types.DATA_CHECK_SUSPEND, "data"),
    loadMoreRequest: makeActionCreator(types.LOAD_MORE_REQUEST, "filters"),
    downloadChecks: makeActionCreator(types.DOWNLOAD_CHECKS_REQUEST, "filters", "format", "onFinish"),
    imageCheck: makeActionCreator(types.IMAGE_CHECK_REQUEST, "idAccount", "checkNumber", "dateCheck"),
    loadInitChecks: makeActionCreator(types.ALL_CHECKS_INIT),
};

export const selectors = {
    getFetching: ({ checks }) => checks.fetching,
    checkbooks: ({ checks }) => checks.checkbooks,
    checks: ({ checks }) => checks.checks,
    accountNumber: ({ checks }) => checks.accountNumber,
    initialCheckNumber: ({ checks }) => checks.initialCheckNumber,
    finalCheckNumber: ({ checks }) => checks.finalCheckNumber,
    currentCheckbook: ({ checks }) => checks.currentCheckbook,
    isFetchingCheckUrl: ({ checks }) => checks.fetchCheckUrl,
    getDataCheckToSuspend: ({ checks }) => checks.dataFormToSuspend,
    getOffset: ({ checks }) => checks.offset,
    getTotalPages: ({ checks }) => checks.totalPages,
    getTotalCount: ({ checks }) => checks.totalCount,
    getPageNumber: ({ checks }) => checks.pageNumber,
    getDocumentImage: ({ checks }) => checks.documentImage,
};
