import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import MultiSelectField from "pages/_components/fields/MultiSelectField";
import PhoneInput from "pages/_components/fields/PhoneInput";
import TextField from "pages/_components/fields/TextField";
import Selector from "pages/_components/fields/formik/Selector";
import Stepper from "pages/_layouts/DefaultStepperLayout/Stepper";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useEffect } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions, selectors } from "reducers/administration/usersInvite";
import { actions as groupActions, selectors as groupSelectors } from "reducers/administration/groups";
import { compose } from "redux";
import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";
import * as Yup from "yup";
import { Redirect } from "react-router-dom";
import { REGION_ECU } from "constants.js";
import { isNil } from "lodash";
import { signatureLevelOptions } from "util/administration";
import { useGroupList, useSignatureLevelList } from "./hooks/userInviteHooks";
import { steps } from "./utils/users";

const FORM_ID = "administration.users.invite";

const UserInviteStep2 = (props) => {
    const { groups, groupsExtendedInfo } = props;
    const preferredCountries = ["ec"];
    const areaCodes = { ve: ["412", "414", "416", "424", "426"] };
    const groupListOptions = useGroupList(groups, groupsExtendedInfo);
    const signatureLevelListOptions = useSignatureLevelList(
        configUtils.getArray("administration.signatures.signatureLevels"),
    );
    const countries = configUtils.getArray("mobileNumber.country.codes.enabled", ["ec"]);

    useEffect(() => {
        const { dispatch } = props;
        dispatch(groupActions.loadListRequest());
    }, []);

    const handleBack = () => {
        const { dispatch } = props;
        dispatch(routerActions.goBack());
    };

    const handleCancel = () => {
        const { dispatch } = props;
        dispatch(routerActions.push("/administration/users"));
    };

    const signaturesConfiguration = () => (
        <Box
            display="flex"
            column
            background="component-background"
            borderRadius="lg"
            className="py-5 px-7 box-shadow-small"
            fullHeight>
            <div className="form-group form-group--select">
                <Box className="mb-4">
                    <Text size="5" bold labelKey="administration.users.invite.signatureLevel.label" />
                </Box>
                <Box className="mt-4 mb-3">
                    <Text size="7" color="text" labelKey="administration.signatures.create.info" />
                </Box>
                <Field
                    component={Selector}
                    options={signatureLevelOptions(signatureLevelListOptions, i18nUtils)}
                    idForm={FORM_ID}
                    name="signatureLevel"
                    renderAs="radio"
                    column
                    gap="1"
                    cControlClassName="mb-3"
                />
            </div>
        </Box>
    );

    const groupsConfiguration = () => (
        <Box
            display="flex"
            column
            background="component-background"
            borderRadius="lg"
            className="py-5 px-7 box-shadow-small"
            fullHeight>
            <Box className="mb-4">
                <Text size="5" bold labelKey="administration.users.invite.groups.add" />
            </Box>
            <Box className="mb-5">
                <Text size="7" labelKey="administration.users.invite.groups.add.info" />
            </Box>
            {groupListOptions.length === 0 && (
                <Field
                    component={MultiSelectField}
                    idForm={FORM_ID}
                    name="groups"
                    options={[]}
                    labelNoMarginTop
                    disabled
                    withSelectedLabel="administration.users.invite.groups.placeholder"
                    withSelectedLabelNoMoreOptions="administration.users.invite.groups.placeholder2"
                />
            )}

            {groupListOptions.length >= 1 && (
                <Field
                    component={MultiSelectField}
                    idForm={FORM_ID}
                    name="groups"
                    options={groupListOptions}
                    labelNoMarginTop
                    withSelectedLabel={i18nUtils.get("administration.users.invite.groups.placeholder")}
                    withSelectedLabelNoMoreOptions={i18nUtils.get("administration.users.invite.groups.placeholder2")}
                />
            )}
        </Box>
    );

    const userMobileNumber = (mobileNumber) => {
        if (mobileNumber && mobileNumber.startsWith("09")) {
            return `593${mobileNumber.substring(1)}`;
        }

        return mobileNumber;
    };

    const { isInvitingNewUser, existentUserInfo, selectedDocument } = props;

    if (!selectedDocument) {
        return <Redirect to="/administration/users/invite" />;
    }
    const inputFieldsViewMode = isInvitingNewUser && isNil(existentUserInfo) ? "edit" : "view";

    return (
        <>
            <Notification scopeToShow="administrationUserInvite" />
            <Head title="administration.users.invite.title" onBack={handleBack} />
            <Form>
                <Box className="mb-3">
                    <Box background="white" borderRadius="lg" className="main-container p-7 pb-7 mt-3 box-shadow-small">
                        <Stepper className="stepper-default mb-5" stepsList={steps} currentStep={2} completed={1} />
                    </Box>
                </Box>
                <MainContainer
                    className="main-container border-radius-lg p-7 box-shadow-small background-white"
                    showLoader={false}>
                    <Row gapY="2" className="mb-8 full-height">
                        <Box display="flex" className="mt-5">
                            <Text
                                component="h2"
                                className="m-0"
                                size="4"
                                bold
                                labelKey="administration.users.invite.personal.data.subtitle"
                            />
                        </Box>
                        <Col xs={6}>
                            <Field
                                mode={inputFieldsViewMode}
                                component={TextField}
                                idForm={FORM_ID}
                                name="firstName"
                                type="text"
                                classNameForViewMode="view-mode-input"
                                // pattern="^[a-zA-Z0-9 ]*$"
                            />
                        </Col>
                        <Col xs={6}>
                            <Field
                                mode={inputFieldsViewMode}
                                component={TextField}
                                idForm={FORM_ID}
                                name="lastName"
                                // pattern="^[a-zA-Z0-9 ]*$"
                                type="text"
                                classNameForViewMode="view-mode-input"
                            />
                        </Col>
                        <Col xs={6}>
                            <Field
                                // mode={inputFieldsViewMode}
                                component={TextField}
                                idForm={FORM_ID}
                                name="email"
                                type="text"
                                pattern="^[a-zA-Z0-9ñ@+._-]*$"
                            />
                        </Col>
                        <Col xs={6}>
                            <Field
                                component={PhoneInput}
                                idForm={FORM_ID}
                                idField={`${FORM_ID}.mobilePhone`}
                                name="mobilePhone"
                                className="remove-flex"
                                preferredCountries={preferredCountries}
                                areaCodes={areaCodes}
                                enableAreaCodes={preferredCountries}
                                openUp
                                mobilePhone={userMobileNumber(existentUserInfo?.mobilePhone)}
                                onlyCountries={countries}
                            />
                        </Col>
                        <Box className="mt-6 mb-5">
                            <Text
                                component="h2"
                                className="m-0"
                                size="4"
                                bold
                                labelKey="administration.users.invite.initial.configuration.subtitle"
                            />
                        </Box>
                        <Col xs={6}>{signaturesConfiguration()}</Col>
                        <Col xs={6}>{groupsConfiguration()}</Col>
                    </Row>
                </MainContainer>

                <Row className="mt-7">
                    <Col xs={3} xsOffset={3}>
                        <Button
                            bsStyle="outline"
                            label="global.cancel"
                            onClick={() => handleCancel()}
                            btnUppercase={false}
                            block
                        />
                    </Col>
                    <Col xs={3}>
                        <Button
                            bsStyle="primary"
                            label="global.continue"
                            loading={false}
                            type="submit"
                            btnUppercase={false}
                            block
                        />
                    </Col>
                </Row>
            </Form>
        </>
    );
};

const getGroupNames = (groups) => {
    if (groups != null && groups.length > 0) {
        return groups.map((x) => x.name);
    }
    return [];
};

const mapStateToProps = (state) => ({
    encryptedDocument: selectors.getEncryptedDocument(state),
    selectedDocument: selectors.getSelectedDocument(state),
    isInvitingNewUser: selectors.isInvitingNewUser(state),
    existentUserInfo: selectors.getExistentUserInfo(state),
    groups: groupSelectors.getGroups(state)?.filter((g) => !g.blocked && !g.deleted),
    groupsExtendedInfo: groupSelectors.getGroupsExtendedInfo(state),
});

UserInviteStep2.propTypes = {
    dispatch: func.isRequired,
    isInvitingNewUser: bool.isRequired,
    existentUserInfo: shape({}).isRequired,
    groups: arrayOf(shape({ id: string, label: string })).isRequired,
    selectedDocument: shape({ document: string, type: string }).isRequired,
    groupsExtendedInfo: shape({}).isRequired,
};

UserInviteStep2.defaultProps = {};

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const { existentUserInfo, groups } = props;

            return {
                firstName: existentUserInfo ? existentUserInfo.firstName : "",
                lastName: existentUserInfo ? existentUserInfo.lastName : "",
                mobilePhone: existentUserInfo ? existentUserInfo.mobilePhone : "",
                email: existentUserInfo ? existentUserInfo.email : "",
                groups: groups && groups.length === 1 ? [groups[0]] : "",
            };
        },

        validationSchema: (props) => {
            const { isInvitingNewUser } = props;
            return Yup.object().shape({
                firstName: isInvitingNewUser
                    ? Yup.string().required(i18nUtils.get("administration.users.firstNameEmpty"))
                    : Yup.string().notRequired(),
                lastName: isInvitingNewUser
                    ? Yup.string().required(i18nUtils.get("administration.users.lastNameEmpty"))
                    : Yup.string().notRequired(),
                email: isInvitingNewUser
                    ? Yup.string()
                          .email()
                          .required(i18nUtils.get("administration.users.emailEmpty"))
                    : Yup.string().notRequired(),
                mobilePhone: isInvitingNewUser
                    ? Yup.string().required(i18nUtils.get("administration.users.emailMobilePhone"))
                    : Yup.string().notRequired(),
                signatureLevel: Yup.string().required(i18nUtils.get("administration.users.emptySignature")),
                groups: Yup.array()
                    .required(i18nUtils.get("administration.users.mustSelectGroup"))
                    .min(1, i18nUtils.get("administration.users.mustSelectGroup")),
            });
        },
        handleSubmit: (data, formikBag) => {
            const {
                dispatch,
                selectedDocument,
                encryptedDocument,
                isInvitingNewUser,
                existentUserInfo,
            } = formikBag.props;

            const { environments, groups } = data;
            const listEnvironments = [];
            const environmentNames = [];

            if (environments) {
                environments.forEach((el) => {
                    listEnvironments.push(el.idEnvironment);
                    environmentNames.push(el.name);
                });
            }

            const params = {
                ...data,
                environments: listEnvironments,
                firstName: isInvitingNewUser ? data.firstName : existentUserInfo.firstName,
                lastName: isInvitingNewUser ? data.lastName : existentUserInfo.lastName,
                email: data && data.email ? data.email : existentUserInfo.email,
                mobilePhone:
                    // eslint-disable-next-line no-nested-ternary
                    data && data.mobilePhone && data.mobilePhone?.value
                        ? data.mobilePhone.areaCode + data.mobilePhone.value
                        : data && data.mobilePhone
                        ? data.mobilePhone
                        : existentUserInfo.mobilePhone,
                prefix: data && data.mobilePhone && data.mobilePhone?.prefix ? data.mobilePhone.prefix : "",
                document: encryptedDocument,
                docCountry: REGION_ECU,
                docType: selectedDocument.type,
                docNumber: selectedDocument.document,
                environmentNames,
                groupNames: groups ? getGroupNames(groups) : [],
                isInvitingNewUser,
            };

            dispatch(actions.userInvitePreview(params, formikBag));
        },
    }),
)(UserInviteStep2);
